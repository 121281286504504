import React, { useEffect, useState } from "react";
import { useNotification } from "../../utils/NotificationContext";
import config from '../../utils/config'
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";

function Notifications() {

    const [evaluations, setEvaluations] = useState([])
    const { notifEvaluations } = useNotification()

    const [loading, setLoading] = useState(true); // État pour indiquer si le chargement est en cours

    const navigate = useNavigate()


    const setLuEvaluation = (idEleve) => {
        const ids = evaluations.map(evaluation => evaluation.id)
        
        fetch(`${config.apiUrl}/encadrement/set_lu_evaluations/`, {
            method: "POST",
            headers: {
                "Content-type" : "application/json"
            },
            body : JSON.stringify({ ids })
        })
        .then(res => res.json())
        .then(data => {
            navigate(`/evaluations?idEleve=${idEleve}`)
        })
        
        // const url = 
        // window.location.href = url;
    }



  const verifiedNoReadEvaluations = async () => {
      const evaluationsData = [];
      const user = JSON.parse(localStorage.getItem('user'));
      const elevesId = user && (user.typeCompte === "Professeur" ? user.ideleves : user.idEleves)
      
      const fetchEvaluations = async (idUser, eleveId) => {
          const response = await fetch(`${config.apiUrl}/encadrement/evaluations/${idUser}/${eleveId}`);
          const data = await response.json();
          return data;
      };

      if(elevesId){
        if (user && Array.isArray(elevesId)) {
            // Utilisez Promise.all pour attendre que toutes les requêtes réseau soient terminées
            const evaluationsPromises =elevesId.map(eleve => fetchEvaluations(user.id, eleve));
            const evaluationsDataArray = await Promise.all(evaluationsPromises);
  
            // Ajouter les données des évaluations à evaluationsData
            evaluationsDataArray.forEach(data => evaluationsData.push(...data));
        }
  
        // Filtrer les évaluations non lues
        const evaluationsFilter = evaluationsData.filter(evaluation => !evaluation.lu);
  
        // Mettre à jour les évaluations avec setEvaluations
        setEvaluations(evaluationsFilter);
      }
  };


useEffect(() => {
    const fetchData = async () => {
        await verifiedNoReadEvaluations();
        setLoading(false); // Une fois les données chargées, définissez le chargement sur false
    };

    if (notifEvaluations.length === 0) {
        fetchData();
    }
}, [notifEvaluations]);

useEffect(() => {

    if(notifEvaluations.length === 0){
        verifiedNoReadEvaluations()
    }else{
        const evaluations = notifEvaluations.filter(notification => !notification.lu)
        setEvaluations(prevEvaluations => {
            // Fusionner les deux tableaux
            const mergedEvaluations = [...prevEvaluations, ...evaluations];
            // Utiliser un objet Set pour éliminer les doublons
            const uniqueEvaluations = Array.from(new Set(mergedEvaluations && mergedEvaluations.map(evaluation => evaluation.id)))
            .map(id => mergedEvaluations.find(evaluation => evaluation.id === id))
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            return uniqueEvaluations;
          });
        setLoading(false)
    }

}, [notifEvaluations])


  return (
    <div className="row layout-spacing layout-top-spacing" id="cancel-row">
      <div className="col-lg-12">
        {loading ? <center><CircularProgress size={40} /></center> : 
            (
                evaluations.length === 0 &&
                <h4 className="text-center mt-4">Pas de notifications</h4>
            )        
        }

        {
            evaluations.length > 0 &&
            <>
                <h5 className="mt-3">
                {evaluations.filter(evaluation => !evaluation.lu).length === 0 
                    ? "Evaluations"
                    : `Evaluations (${evaluations.filter(evaluation => !evaluation.lu).length})`
                }
                </h5>
                {evaluations.map(evaluation => (
                    <>
                    <div className="mt-3" style={{backgroundColor: `${evaluation.lu ? '#fff' : '#eee'}`, padding: 24}} key={evaluation.id}>
                        <a onClick={() => setLuEvaluation(evaluation.eleve.id)} style={{ textDecoration: 'none', cursor: 'pointer' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginLeft: '10px' }}>
                                    <img src={config.apiUrl+evaluation.tuteur.photo} alt="Avatar" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                                </div>
                                <div style={{ flex: 1, marginLeft: 10 }}>
                                    <span style={{fontSize: 14}}>Mr/Mme {evaluation.tuteur.last_name} vous a évalué, {moment(evaluation.date_evaluation).fromNow()}</span> <br />
                                    <span style={{fontWeight: 'bold'}}>{evaluation.commentaire.length > 20 ? evaluation.commentaire.slice(0,40) + '...' : evaluation.commentaire}</span>
                                </div>
                                <hr />
                            </div>
                        </a>
                    </div>
                    </>
                ))}
            </>
        }
      </div>
    </div>
  );
}

export default Notifications;
