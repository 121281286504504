import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import mtn from '../../../assets/img/mtn.png'
import airtel from '../../../assets/img/airtel.png'
import { MenuItem, TextField, CircularProgress } from "@mui/material";
import 'react-toastify/dist/ReactToastify.css';
import config from '../../../utils/config'

const ProcessPayment = () => {
    const [selectedStudent, setSelectedStudent] = useState("");
    const [selectedProf, setSelectedProf] = useState("");
    const [showListProfs, setShowListProfs] = useState(false)
    const [eleve, setEleve] = useState(null)
    const [eleves, setEleves] = useState([])
    const [loadingEleves, setLoadingEleves] = useState(true)
    const [showBtn, setShowBtn] = useState(false)
    const [showFieldPaiement, setShowFieldPaiement] = useState(false)
    const [phone, setPhone] = useState("");
    const [msgPhone, setMsgPhone] = useState("");
    const [operateur, setOperateur] = useState("");
    const [notePayment, setNotePayment] = useState("");
    const [codePayment, setCodePayment] = useState("");
    const [error, setError] = useState("");
    const [isLoadingFrais,setIsLoadingFrais] = useState(false)
    const [encadrement, setEncadrement] = useState(null)
    const [isLoadingRequest, setIsLoadingRequest] = useState(false);
    const [isLoadingPaymentProcessing, setIsLoadingPaymentProcessing] = useState(false);
    
    const [messageMtn, setMessageMtn] = useState("")
    const userInfos = JSON.parse(localStorage.getItem('user'))


    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoadingRequest(true)
    
        const data = {
          phone : phone
        }
    
    
        const endpoint = operateur === "MTN" ? `${config.apiUrl}/encadrement/mm_payment` : `${config.apiUrl}/encadrement/am_payment`;
    
        fetch(endpoint+`/${selectedStudent}/${selectedProf}/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(result => {
            
            if(result.pending){
              setMessageMtn("Vous avez une transaction en attente pour ce numéro , appuyez sur *105# puis 5 puis 1 puis 1 puis CODE_PIN, afin de voir vos transactions en attente")
              setIsLoadingPaymentProcessing(false)
              setIsLoadingRequest(false)
            }else{
              setMessageMtn("Validation du paiement sur votre téléphone (Ne quittez pas la page) 😉")
            }
            setIsLoadingPaymentProcessing(true)
            setIsLoadingRequest(false)
        })
        .catch(error => {
            console.error('Erreur lors de la requête:', error);
            setError(error)
        });
      };
    
      useEffect(() => {
        let intervalId1;
      
        // const fetchPaymentStatusAM = () => {
        //   fetch(`${config.apiUrl}/encadrement/get_status_cg_am/`, {
        //     method : 'GET',
        //   })
        //   .then(response => response.json())
        //   .then(data => {

        //     // Si la condition pour arrêter l'intervalle est remplie, nettoyez l'intervalle
        //     if (String(data.status) !== "pending") {
        //       setNotePayment(data.note);
        //       setCodePayment(data.code);
        //       setIsLoadingPaymentProcessing(false);
        //       clearInterval(intervalId);
        //     }
    
        //     if (String(data.code) === "200") {
        //       setNotePayment("Paiement effectué avec succès 🥳");
        //       setCodePayment(data.code);
        //       setIsLoadingPaymentProcessing(false);
        //       clearInterval(intervalId);
        //     }
    
        //   })
        //   .catch(error => console.error('Erreur lors de la requête fetch:', error));
        // };
      
    
        const fetchPaymentStatusMM = () => {
          fetch(`${config.apiUrl}/encadrement/get_status_mm/${selectedStudent}/${selectedProf}/`, {
            method : 'GET',
          })
          .then(response => response.json())
          .then(data => {
            // Faites quelque chose avec la réponse
            if(String(data.data.status) === "SUCCESSFUL"){
              setNotePayment("Paiement effectué avec succès 🥳!");
    
              setCodePayment("200");
              setIsLoadingPaymentProcessing(false);
              clearInterval(intervalId1);
            }
            if(String(data.data.status) === "FAILED" && String(data.data.reason) === "LOW_BALANCE_OR_PAYEE_LIMIT_REACHED_OR_NOT_ALLOWED"){
              setNotePayment("Transaction échouée 😔, votre solde est insuffisant");
              setCodePayment("500");
              setIsLoadingPaymentProcessing(false);
              clearInterval(intervalId1);
            }
    
            if(String(data.data.status) === "FAILED" && String(data.data.reason) === "COULD_NOT_PERFORM_TRANSACTION"){
              setNotePayment("Transaction échouée 😔, vous avez rejeté le paiement");
              setCodePayment("500");
              setIsLoadingPaymentProcessing(false);
              clearInterval(intervalId1);
            }
          })
          .catch(error => console.error('Erreur lors de la requête fetch:', error));
        };
    
        if (isLoadingPaymentProcessing) {
          // Appeler la fonction immédiatement
            fetchPaymentStatusMM();
            // Mettre en place un intervalle pour envoyer la requête chaque seconde
            intervalId1 = setInterval(fetchPaymentStatusMM, 1000);
        }
      
        // Nettoyer l'intervalle lorsque le composant est démonté ou lorsque isLoadingPaymentProcessing devient faux
        return () => {
          clearInterval(intervalId1);
        };
      }, [isLoadingPaymentProcessing]);




    let formatTelephone = /^(06|05|04)[0-9]{7}$/;

    useEffect(() => {
        if (phone === "") {
        setMsgPhone("Le numéro de téléphone est obligatoire");
        } else {
            if (formatTelephone.test(phone)) {
                setMsgPhone("");
            } else {
                setMsgPhone("Voici le format accepté : 06XXXXXXX ou 05XXXXXXX ou 04XXXXXXX");
            }
        }
    }, [phone]);

    useEffect(() => {
        setError("")
        if(phone !== "" && formatTelephone.test(phone)){
            setShowBtn(true)
            if (String(phone).startsWith("06")) {
              setOperateur("MTN")
            }else{
              setOperateur("AIRTEL")
            }
        }else{
          setOperateur("")
          setShowBtn(false)
  
        }
    // eslint-disable-next-line
    }, [phone])
  

  useEffect(() => {
    if(selectedProf !== "" && userInfos.typeCompte === 'Tuteur'){
        setIsLoadingFrais(true)
        fetch(`${config.apiUrl}/comptes/get_encadrement/${selectedStudent}/${selectedProf}/`)
        .then(res => res.json())
        .then(data => {
          setEncadrement(data.data)
        })
        .catch(error => {
          console.error("Erreur => ", error)
        })
        setIsLoadingFrais(false)
        setShowFieldPaiement(true)
    }
  }, [selectedProf])

  useEffect(() => {
    if(selectedStudent !== "" && userInfos){
        if(userInfos.typeCompte === "Tuteur"){
            fetchEleve()
            setShowListProfs(true)
        }
    }
  }, [selectedStudent])

  
  const fetchEleve = async () => {
    const url = `${config.apiUrl}/comptes/eleves/${selectedStudent}/`;

    const response = await fetch(url)
    const data = await response.json()
    setEleve(data)
  }

  const fetchElevesData = async () => {
    const eleves_staging = [];
    setLoadingEleves(true)
    if(userInfos){
        const enfants = userInfos.idEleves
        for(const ideleve of enfants) {
            const url = `${config.apiUrl}/comptes/eleves/${ideleve}/`;

            try {
                const response = await fetch(url, {
                    method: "GET",
                });
                const data = await response.json();

                eleves_staging.push(data);

            } catch(error) {
                console.log("Erreur => ", error);
            }
        }

        // Mettez à jour le state une fois que toutes les données ont été récupérées
        setEleves(eleves_staging);
        setLoadingEleves(false)
        
        }
    };

    useEffect(() => {
        fetchElevesData();
        setMsgPhone("")
    }, []);

  return (
    <>
      <section className="signup-area">
        
        <div className="container">
          <div className="row align-items-center">
            <center>
              <div className="col-lg-6 col-12">
                <div className="signup-form">
                  <h2 style={{fontFamily: 'arial'}}>Effectuer votre paiement</h2>
                    <TextField
                        select
                        label="Sélectionnez l'élève"
                        variant="outlined"
                        fullWidth
                        sx={{mb: 7, mt: 3}}
                        value={selectedStudent}
                        onChange={(e) => setSelectedStudent(e.target.value)}
                    >
                        {eleves && eleves.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.nom} {option.prenom}
                        </MenuItem>
                        ))}
                    </TextField>

                    {
                    showListProfs &&
                    <TextField
                        select
                        label="Sélectionnez le professeur concerné"
                        variant="outlined"
                        fullWidth
                        sx={{mb: 3, mt: -3}}
                        value={selectedProf}
                        onChange={(e) => setSelectedProf(e.target.value)}
                    >
                        {eleve && Array.isArray(eleve.professeurs) && eleve.professeurs.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.last_name} {option.first_name} 
                        </MenuItem>
                        ))}
                    </TextField>
                    }

                    {
                        showFieldPaiement &&
                        <>
                            {
                              isLoadingFrais ?
                              <center>
                                <CircularProgress size={24} />
                              </center>
                              :
                              <h6>
                                {
                                  encadrement && `Une somme de ${encadrement.frais_mensuels + encadrement.frais_mensuels*(3.5/100)} francs CFA sera débitée de votre compte`
                                }
                              </h6>
                            }
                            <TextField
                                className="w-100 mt-3"
                                type="text"
                                name="numéro de téléphone"
                                label="Numéro de téléphone Mtn ou Airtel"
                                value={phone}
                                onChange={e => {
                                setPhone(e.target.value)
                                }}
                                placeholder="064723424"
                                variant="outlined"
                                required
                                error={Boolean(msgPhone)}
                                helperText={msgPhone}
                                style={{marginBottom: '20px'}}
                            />  
                            <div style={{height: '50px'}}>
                            {
                                operateur === "MTN" ?
                                <img src={mtn} style={{width: 50, height: '100%'}} />:
                                (
                                operateur === "AIRTEL" ?
                                <img src={airtel} style={{width: 50, height: '100%'}} />:""
                                )
                                }
                            </div>
                            {
                                showBtn && !isLoadingPaymentProcessing &&
                                <LoadingButton
                                    loading={isLoadingRequest}
                                    variant='contained'
                                    sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2}}
                                    loadingIndicator={<CircularProgress color="inherit" size={30} />}
                                    onClick={handleSubmit}
                                >
                                    Effectuer
                                </LoadingButton> 
                            }
                            <br/>
                            {
                              isLoadingPaymentProcessing && !isLoadingRequest &&
                              <center>
                                <h6>{messageMtn}</h6>
                                <CircularProgress size={24} sx={{mt: 2}} />
                              </center>
                            }
                            {
                              (!isLoadingPaymentProcessing && codePayment === "200") ?
                              <p className="mt-4" style={{color: 'green'}}>{!isLoadingPaymentProcessing && "Paiement réussi 🥳!!"}</p>
                              :
                              <p className="mt-4" style={{color: 'red'}}>{!isLoadingPaymentProcessing && notePayment}</p>
                            }
                            <p className="text-center mt-4" style={{color : 'red'}}>{error}</p>                            
                            <br/>
                            {/* {
                              operateur === "MTN" && isLoadingPaymentProcessing &&
                              <>
                                <b>Remarques : </b> 
                                <p>
                                  Si vous rentrez un code PIN incorrect, veuillez réessayer en appuyant sur *105# puis 5 puis 1, afin de voir vos transactions en attente
                                </p>
                              </>
                            } */}
                        </>
                    }
                </div>
              </div>
            </center>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProcessPayment;
