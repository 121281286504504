import React, { useEffect, useState } from 'react';
import { TextField,Rating, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { LoadingButton } from "@mui/lab";
import { ToastContainer, toast } from "react-toastify";
import config from '../../utils/config'

function StarRating({idProf, userInfos, idEleve}) {
  const [rating, setRating] = useState(0);
  const [commentaire, setCommentaire] = useState("")
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [professeur , setProfesseur] = useState(null)


  useEffect(() => {
    if(commentaire === ""){
      setDisabledBtn(true)
    }else{
      setDisabledBtn(false)
    }
  }, [commentaire])




  const handleSubmit = e => {
    e.preventDefault()
    setIsLoading(true)
    const url = `${config.apiUrl}/encadrement/evaluation/${idProf}/${idEleve}/`;

    let formData = new FormData();
    formData.append("rating", rating);
    formData.append("commentaire", commentaire);

    fetch(
        `${url}`,
        {
          method: "POST",
          body: formData,
          headers: {
            'Authorization': `Bearer ${userInfos && userInfos.token}`
          }
        }
      )
      .then((response) => response.json())
      .then((data) => {
            if(data.error){
              toast.error(`${data.error}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });

            }else{
              toast.success("Soumission avec succès , merci , nous allons prendre ce que vous avez dit au sérieux !✨", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                setRating(0.0)
                setCommentaire('')
            }
            setIsLoading(false)
      })
      .catch((error) => console.log("ERREUR => ", error));
  
  }

  const handleRatingChange = (event) => {
    setRating(event.target.value);
  };

  useEffect(() => {
    if(idProf){
      const url = `${config.apiUrl}/comptes/professeurs/${idProf}`;

      fetch(
          `${url}`,
          {
            method: "GET",
          }
        )
        .then((response) => response.json())
        .then((data) => {
              setProfesseur(data)
        })
        .catch((error) => console.log("ERREUR => ", error));
    
  }
  

  }, [idProf])

  return (
    <div className="mx-auto text-center mt-4 col-md-6 col-lg-6 col-12 ">
      <ToastContainer />
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <h5><b>NB :</b> La note n'est pas obligatoire mais le commentaire l'est</h5>
        <Rating
          name="rating"
          value={rating}
          precision={0.5}
          onChange={handleRatingChange}
          icon={<StarIcon fontSize="inherit" style={{ color: '#ff9f00', fontSize: 40 }} />}
          emptyIcon={<StarBorderIcon fontSize="inherit" style={{ color: '#ff9f00',fontSize: 40 }} />}
        />
        
        <TextField
          id="outlined-multiline-static"
          label="Noter et commenter le travail du professeur, ou bien des plaintes ..."
          multiline
          placeholder={`J'ai remarqué que le professeur ${professeur && professeur.last_name} ${professeur && professeur.first_name} vient souvent en retard ...`}
          rows={5}
          value={commentaire}
          onChange={e => setCommentaire(e.target.value)} 
        />

        <LoadingButton
          loading={isLoading}
          variant='contained'
          disabled={disabledBtn}
          loadingIndicator={<CircularProgress color="inherit" size={30} />}
          sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2, padding: 4, color:'white'}}
          onClick={handleSubmit}
        >
          Soumettre
        </LoadingButton>
      </Box>
    </div>
  );
  
}

export default StarRating;
