import React from "react";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

const index = ({ value, change, placeholder, type,label }) => {
  return (
      <FormControl sx={{ m: 1, width: '100%' }}>
          <TextField
            id="outlined-password-input"
            label={label}
            type={type}
            value={value}
            onChange={e => change(e.target.value)}
            placeholder={placeholder}
          />
      </FormControl>
  );
};

export default index;
