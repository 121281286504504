import React, { useState } from 'react';
import ProcessPayment from './ProcessPayment';
import HistoriquePayment from './HistoriquePayment';
import { Tabs, Tab } from '@mui/material';

function Paiement() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className='mt-4'>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Paiement" />
        <Tab label="Historique" />
      </Tabs>
      <br/>
      {activeTab === 0 && <ProcessPayment />}
      {activeTab === 1 && <HistoriquePayment />}
    </div>
  );
}

export default Paiement;
