import React, { useEffect } from "react";
import Header from "./Components/Header";
import Main from "./Components/Main";
import { UserProvider, useUser } from "./utils/UserContext";
import { useLocation } from "react-router-dom";
import config from './utils/config'
import { NotificationProvider } from "./utils/NotificationContext";

function RouteChanged() {
  const {  setUserInfos  } = useUser()
  const location = useLocation();

  useEffect(() => {    
    if(localStorage.getItem('logout')){
      localStorage.removeItem('logout')
    }else{
      if(localStorage.getItem('user')){
        setUserInfos(JSON.parse(localStorage.getItem('user')))
      }else{
        window.location.href = config.client

      }
    }
    let main = document.querySelector(".main-container")
    let overlay = document.querySelector(".overlay")
    main.classList.add("sidebar-closed")
    main.classList.add("sbar-open")
    overlay.classList.remove("show")
  
  }, [location, setUserInfos]);

  return null;
}


function App() {
  
  return (
    <UserProvider>
      <NotificationProvider>
      <RouteChanged />
      <Header />
      <Main/>
      </NotificationProvider>
    </UserProvider>
  );
}

export default App;
