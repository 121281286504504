import React, { useEffect, useState } from 'react'
import UpdateEleve from '../../Components/UpdateEleve';
import { useUser } from '../../utils/UserContext';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../utils/config'

function EdifProfileEleve() {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("")
    const [eleve, setEleve] = useState(null)
    const { userInfos, setUserInfos } = useUser()


    const urlParams = new URLSearchParams(window.location.search);
    const dataUrl = urlParams.get('idEleve');


    useEffect(() => {
        const url = `${config.apiUrl}/comptes/eleves/${dataUrl}/`;

        fetch(url, {
            method: "GET",
        })
        .then(response => response.json())
        .then(data => {
            if (data.message) {
                setError(data.message);
            } else {
                setEleve(data)
            }
        })
        .catch((error) => console.log("Erreur => ", error));      
    }, [])
    

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if(file && file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg') || file.name.toLowerCase().endsWith('.png')){
            setError('')
            let formData = new FormData();
            setIsLoading(true)
            formData.append("photo", file);

            const url = `${config.apiUrl}/comptes/eleve/${dataUrl}/photo/update/`;
    
            fetch(url, {
                method: "PUT",
                body: formData,
                headers: {
                    'Authorization': `Bearer ${userInfos.token}`
                  }
            })
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    setError(data.message);
                } else {
                    const profile = document.querySelector("#profile-pic")
                    profile.src = URL.createObjectURL(file)
                    localStorage.setItem('user', JSON.stringify(data))
                    setUserInfos(data)

                    toast.success('Mise à jour réussie de l\'image réussie !', {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }
                setIsLoading(false)
            })
            .catch((error) => console.log("Erreur => ", error));
        }else{
            setError("Veuillez sélectionner une image (.png, .jpg)")
        }

        

    };


  return (
        <div className="account-settings-container layout-top-spacing">
            <ToastContainer />
            <div className="account-content">
                <div className="row mb-3">
                    <div className="col-md-12">
                        <h2>Mise à jour des informations de {eleve && eleve.nom}</h2>
                    </div>
                </div>

                <div className="tab-content" id="animateLineContent-4">
                    <div className="tab-pane fade show active" id="animated-underline-home" role="tabpanel" aria-labelledby="animated-underline-home-tab">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                                <form className="section general-info">
                                    <div className="row">
                                        <div className="col-lg-11 mx-auto">
                                            <div className="row">
                                                <div className="col-xl-2 col-lg-12 col-md-4">
                                                    <div className="profile-image  mt-4 pe-md-4">
                                                        <div className="d-flex justify-content-center mb-4">
                                                            <img src={eleve &&  (config.apiUrl+eleve.photo)}
                                                            className="rounded-circle" id='profile-pic' alt="example" style={{width: "150px"}} />
                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                            <div className="btn btn-primary btn-rounded">
                                                                <label className="form-label text-white m-1" htmlFor="customFile2"> {isLoading ? "Patientez..." :  "Changer l'image"}</label>
                                                                <input type="file" className="form-control d-none" onChange={handleFileChange} id="customFile2" />
                                                            </div>
                                                        </div>
                                                        <p style={{color : 'red', textAlign: 'center'}}>{error}</p>
                                                    </div>
                                                </div>
                                                <div className="col-xl-10 col-lg-12 col-md-8 mt-md-0 mt-4">
                                                    <div className="form">
                                                        <div className="row">
                                                            <UpdateEleve eleve={eleve} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>                     
  );
}

export default EdifProfileEleve