// config.js

const config = {
    development: {
        apiUrl: 'http://127.0.0.1:8000',
        client : 'http://127.0.0.1:3000',
        clientAdmin : 'http://127.0.0.1:3001',
        clientBoards : 'http://127.0.0.1:3003',
    },
    production: {
        apiUrl: 'https://api.hometeacher.cg',
        client: 'https://hometeacher.cg',
        clientAdmin: 'https://dashboard.hometeacher.cg',
        clientBoards: 'https://boards.hometeacher.cg',
    },
};

const environment = process.env.NODE_ENV || 'development';
module.exports = config[environment];