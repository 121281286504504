import { useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useUser } from "../../utils/UserContext";
import config from '../../utils/config'

const steps = ["Etape 1", "Etape 2", "Etape 3"];

const UpdateEleve = ({eleve}) => {

  const { userInfos, setUserInfos } = useUser()

  useEffect(() => {
    if(eleve){
      setPrenom(eleve.prenom);
      setNom(eleve.nom);
      setDateNaissance(eleve.date_naissance);
      setLieuTravail(eleve.lieu_travail);
      setDifficulte(eleve.difficulte);
      setEcole(eleve.ecole);
      setSexe(eleve.sexe);
  
      setJoursDisponibles(eleve.jours_disponibles);
      setMatieresGenerales(eleve.matieres);
      setClasseGenerale(eleve.classe);
      setPeriodeJournee(eleve.periode_journee);
    }
  }, [eleve])


  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [dateNaissance, setDateNaissance] = useState("");
  const [classe, setClasse] = useState("");
  const [ecole, setEcole] = useState("");
  const [sexe, setSexe] = useState("");

  const [classeGenerale, setClasseGenerale] = useState("");
  const [matieresGenerales, setMatieresGenerales] = useState("");
  const [lieuTravail, setLieuTravail] = useState("");
  const [ville, setVille] = useState("");
  const [difficulte, setDifficulte] = useState("");
  const [periodeJournee, setPeriodeJournee] = useState("");
  const [joursDisponibles, setJoursDisponibles] = useState("");

  const [showBtn, setShowBtn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)
    let formData = new FormData();
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("date_naissance", dateNaissance);
    formData.append("classe", classe);
    formData.append("matieres", matieresGenerales);
    formData.append("ecole", ecole);
    formData.append("lieu_travail", lieuTravail);
    formData.append("ville", ville);
    formData.append("difficulte", difficulte);
    formData.append("sexe", sexe);
    formData.append("periode_journee", periodeJournee);
    formData.append("jours_disponibles", joursDisponibles);
    formData.append("tuteur", userInfos.id);

    const url = `${config.apiUrl}/comptes/update/eleve/${eleve.id}`;


    fetch(url, {
      method: "PUT",
      body: formData,
      headers: {
        'Authorization': `Bearer ${userInfos.token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          setError(data.message);
        } else {
          localStorage.setItem('user', JSON.stringify(data))
          setUserInfos(data)
          toast.success('Mise à jour réussie 😊!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          navigate("/profile");
        }
        setIsLoading(false)
      })
      .catch((error) => console.log("Erreur => ", error));
  };

  const handleNext = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  return (
      <section className="signup-area">
          <div className="container">
            <div className="row align-items-center">
              <ToastContainer />
              <div class="col-12">
                <div class="signup-form">
                <h1 className="text-center">Modification Eleve</h1>
                  <Box sx={{ width: "100%" }}>
                    <Stepper sx={{ mt: 4 }} activeStep={activeStep}>
                      {steps.map((label, index) => {
                        return (
                          <Step key={index}>
                            <StepLabel>{""}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>

                    
                      <Fragment>
                        <form method="post" className="form-group">
                          { activeStep === 0 ? (
                            <Step2
                              nom={nom}
                              setNom={setNom}
                              prenom={prenom}
                              setPrenom={setPrenom}
                              sexe={sexe}
                              setSexe={setSexe}
                              dateNaissance={dateNaissance}
                              setDateNaissance={setDateNaissance}
                              setShowBtn={setShowBtn}
                              setError={setError}
                              ecole={ecole}
                              setEcole={setEcole}
                            />
                          ) : activeStep === 1 ? (
                            <Step3
                              classeGenerale={classeGenerale}
                              setClasseGenerale={setClasseGenerale}
                              setClasse={setClasse}
                              matieresGenerales={matieresGenerales}
                              setMatieresGenerales={setMatieresGenerales}
                              classe={classe}
                              setError={setError}
                              setShowBtn={setShowBtn}
                            />
                          ) : (
                            <Step4
                              lieuTravail={lieuTravail}
                              ville={ville}
                              setLieuTravail={setLieuTravail}
                              difficulte={difficulte}
                              setDifficulte={setDifficulte}
                              setVille={setVille}
                              periodeJournee={periodeJournee}
                              setPeriodeJournee={setPeriodeJournee}
                              joursDisponibles={joursDisponibles}
                              setJoursDisponibles={setJoursDisponibles}
                              setError={setError}
                              setShowBtn={setShowBtn}
                            />
                          )}
                          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            {
                              activeStep > 0 &&
                              <LoadingButton
                              sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2, padding: 4, color:'white'}}
                              onClick={handleBack}
                              >
                                Précédent
                              </LoadingButton>
                            }
                            <Box sx={{ flex: "1 1 auto" }} />
                            {activeStep === steps.length - 1 ? (
                              showBtn &&
                              <LoadingButton
                                loading={isLoading}
                                variant='contained'
                                loadingIndicator={<CircularProgress color="inherit" size={30} />}
                                sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2, padding: 4, color:'white'}}
                                onClick={handleSubmit}
                              >
                                Mettre à jour
                              </LoadingButton>
                            ) : (
                              showBtn &&
                              <LoadingButton
                                sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2, padding: 4, color:'white'}}
                                onClick={handleNext}
                              >
                                Suivant
                              </LoadingButton>
                            )}
                          </Box>
                          <p style={{ color: "red" }}>{error}</p>
                        </form>
                      </Fragment>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </section>
    )
};
export default UpdateEleve;
