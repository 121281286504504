import React, { useEffect, useState } from 'react';
import { CircularProgress, Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import config from '../../utils/config'
import { useUser } from '../../utils/UserContext';
import { useNotification } from '../../utils/NotificationContext';
import {MenuItem } from "@mui/material";
import TextField from '@mui/material/TextField';


function Evaluations() {
  // Supposons que vous ayez une liste d'évaluations appelée `evaluations`
  const [evaluations , setEvaluations] = useState(null)
  const [isLoading , setIsLoading] = useState(false)
  const { userInfos } = useUser()
  const [eleves, setEleves] = useState(null)
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedProf, setSelectedProf] = useState("");
  const [showListProfs, setShowListProfs] = useState(false)
  const [eleve, setEleve] = useState(null)

  const { notifEvaluations, setNotifEvaluations } = useNotification()

  const urlParams = new URLSearchParams(window.location.search);
  const dataUrlEleve = urlParams.get('idEleve');

  useEffect(() => {
    if(dataUrlEleve !== null){
      setSelectedStudent(dataUrlEleve)
    }
  }, [])


  const fetchElevesData = async () => {
    const eleves_staging = [];
    if(userInfos){
      const enfants = userInfos.typeCompte === "Professeur" ? userInfos.ideleves : userInfos.idEleves
      for(const ideleve of enfants) {
        const url = `${config.apiUrl}/comptes/eleves/${ideleve}/`;

        try {
            const response = await fetch(url, {
                method: "GET",
            });
            const data = await response.json();

            eleves_staging.push(data);

        } catch(error) {
            console.log("Erreur => ", error);
        }
    }

    // Mettez à jour le state une fois que toutes les données ont été récupérées
    setEleves(eleves_staging);
    }
};

useEffect(() => {
  if(selectedProf !== "" && userInfos.typeCompte === 'Tuteur'){
      fetchEvaluationsData()
  }
}, [selectedProf])

const fetchEvaluationsData = async () => {
  setIsLoading(true)
  let idProf = selectedProf === "" ? userInfos.id : selectedProf
  const url = `${config.apiUrl}/encadrement/evaluations/${idProf}/${selectedStudent}/`;


  fetch(
      `${url}`,
      {
        method: "GET",
      }
    )
    .then((response) => response.json())
    .then((data) => {
          setEvaluations(data)
          const updatedData = notifEvaluations.map(item => {
              const matchingDataItem = data.find(dataItem => dataItem.id === item.id);
              if (matchingDataItem) {
                  item.lu = true;
              }
              return item;
          });

          setNotifEvaluations(updatedData)
          
          setIsLoading(false)
    })
    .catch((error) => {
      console.log("ERREUR => ", error)
      setIsLoading(false)
    });
}

  const fetchEleve = async () => {
    const url = `${config.apiUrl}/comptes/eleves/${selectedStudent}/`;

    const response = await fetch(url)
    const data = await response.json()
    setEleve(data)
  }

  useEffect(() => {
    if(userInfos){
      fetchElevesData()
    }
  }, [userInfos])

  useEffect(() => {
    if(selectedStudent !== "" && userInfos){
        if(userInfos.typeCompte === "Professeur"){
            fetchEvaluationsData()
        }else{
            fetchEleve()
            setShowListProfs(true)
        }
    }
  }, [selectedStudent])

  return (
    <div className="container mt-4">
      <h3 className="mb-4 text-center">
        {userInfos && userInfos.typeCompte === "Tuteur" ? "Liste de toutes vos évaluations" : "Liste de toutes les évaluations des tuteurs"}
      </h3>

      <TextField
            select
            label="Sélectionnez l'élève"
            variant="outlined"
            fullWidth
            sx={{mb: 7, mt: 3}}
            value={selectedStudent}
            onChange={(e) => setSelectedStudent(e.target.value)}
        >
            {eleves && eleves.map((option) => (
            <MenuItem key={option.id} value={option.id}>
                {option.nom} {option.prenom}
            </MenuItem>
            ))}
        </TextField>

      {
        showListProfs &&
        <TextField
            select
            label="Sélectionnez le professeur concerné"
            variant="outlined"
            fullWidth
            sx={{mb: 7, mt: -3}}
            value={selectedProf}
            onChange={(e) => setSelectedProf(e.target.value)}
        >
            {eleve && Array.isArray(eleve.professeurs) && eleve.professeurs.map((option) => (
            <MenuItem key={option.id} value={option.id}>
                {option.last_name} {option.first_name} 
            </MenuItem>
            ))}
        </TextField>
      }


      {isLoading ? <center><CircularProgress sx={{mt:20}} size={50} /></center> :       
      (evaluations && 
        (
        evaluations.length === 0 ?
        <h4 className='text-center mt-5'>Vous n'avez pas encore noté ce professeur</h4>
        :
        evaluations.map((evaluation) => (
        <div className="card mb-3" key={evaluation.id}>
          <div className="card-body">
            <h5 className="card-title">Évaluation du Professeur <b>{evaluation.professeur.last_name} {evaluation.professeur.first_name}</b></h5>
            <div className="d-flex align-items-center mb-2">
              <span className="me-2"><b>Note :</b></span>
              {/* Affichage de la note en étoiles */}
              <div className="rating">
                <Rating
                    readOnly
                    name="rating"
                    value={evaluation.note}
                    precision={0.5}
                    icon={<StarIcon fontSize="inherit" style={{ color: '#ff9f00', fontSize: 40 }} />}
                    emptyIcon={<StarBorderIcon fontSize="inherit" style={{ color: '#ff9f00',fontSize: 40 }} />}
                    />
              </div>
            </div>
            <p className="card-text mb-3"><b>Commentaire : </b>{evaluation.commentaire}</p>
            <p className="card-text mb-3"><b>Eleve : </b>{evaluation.eleve.nom} {evaluation.eleve.prenom}</p>
            <p className="card-text mb-3"><b>Tutuer : </b>{evaluation.tuteur.last_name} {evaluation.tuteur.first_name}</p>
            <p className="card-text"><b>Date et heure d'Évaluation :</b> {new Date(evaluation.date_evaluation).toLocaleString('fr-FR', { timeZone: 'UTC' })}</p>
          </div>
        </div>
      ))))}
    </div>
  );
}

export default Evaluations;
