import React, { useEffect, useState } from 'react';
import { useUser } from '../../utils/UserContext';
import { CircularProgress } from '@mui/material';
import config from '../../utils/config';
import { Link } from 'react-router-dom';

function Professeurs() {
  const [eleves, setEleves] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [encadrements, setEncadrements] = useState({});
  const { userInfos } = useUser();

  const getJoursMatieres = async (idEleve, idProf) => {
    try {
      const url = `${config.apiUrl}/comptes/get_encadrement/${idEleve}/${idProf}/`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return {
        joursTravail: data.data.jours_travail,
        matieres: data.data.matieres,
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        joursTravail: [],
        matieres: [],
      };
    }
  };

  useEffect(() => {
    if (userInfos) {
      const fetchElevesData = async () => {
        setIsLoading(true);
        const elevesStaging = [];
        const encadrementsStaging = {};

        for (const ideleve of userInfos.idEleves) {
          const url = `${config.apiUrl}/comptes/eleves/${ideleve}/`;

          try {
            const response = await fetch(url, { method: 'GET' });
            const data = await response.json();
            elevesStaging.push(data);

            for (const professeur of data.professeurs) {
              const encadrementData = await getJoursMatieres(data.id, professeur.id);
              encadrementsStaging[professeur.id] = encadrementData;
            }
          } catch (error) {
            console.log('Erreur => ', error);
          }
        }

        setEleves(elevesStaging);
        setEncadrements(encadrementsStaging);
        setIsLoading(false);
      };

      fetchElevesData();
    }
  }, [userInfos]);

  const getAge = (date) => {
    const dateDonnee = new Date(date);
    const dateActuelle = new Date();
    const differenceEnMilliseconds = dateActuelle - dateDonnee;
    const differenceEnAnnees = differenceEnMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
    return Math.floor(differenceEnAnnees);
  };

  return (
    <div className="row layout-spacing layout-top-spacing" id="cancel-row">
      <div className="col-lg-12">
      <h3 className='text-center'>Liste de vos enfants et leurs professeurs associés</h3>
        <div className="widget-content searchable-container list">
          <div className="searchable-items list">
            {isLoading ? (
              <center><CircularProgress sx={{ mt: 20 }} size={50} /></center>
            ) : (
              eleves && Array.isArray(eleves) && eleves.map((eleve) => (
                <div key={eleve.id}>
                  <h5 className='m-4 text-center' style={{ fontWeight: 'bold' }}>
                    - <b>{eleve.nom} {eleve.prenom}</b>
                  </h5>
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      {eleve.professeurs && eleve.professeurs.length > 0 && (
                        <thead style={{ textAlign: 'center' }}>
                          <tr>
                            <th>Professeur</th>
                            <th>Age</th>
                            <th>Genre</th>
                            <th>Jours et heures de travail</th>
                            <th>Matières</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                      )}
                      <tbody style={{ textAlign: 'center' }}>
                        {eleve.professeurs && eleve.professeurs.length > 0 ? (
                          eleve.professeurs.map((professeur) => {
                            const encadrementData = encadrements[professeur.id] || {};
                            return (
                              <tr key={professeur.id}>
                                <td>
                                  <center>
                                    <div className="user-profile">
                                      <img
                                        src={config.apiUrl + professeur.photo}
                                        alt="avatar"
                                        width={100}
                                        height={100}
                                      />
                                      <div className="user-meta-info">
                                        <p className="user-name">{professeur.last_name}</p>
                                        <p className="user-work">{professeur.first_name}</p>
                                      </div>
                                    </div>
                                  </center>
                                </td>
                                <td>
                                  <p className="usr-ph-no">{getAge(professeur.date_naissance)} ans</p>
                                </td>
                                <td>
                                  <p className="usr-ph-no">{professeur.sexe}</p>
                                </td>
                                <td>
                                  {Object.entries(encadrementData.joursTravail || {}).map(([jour, heures]) => (
                                    <p key={jour}>{jour} : {heures}</p>
                                  ))}
                                </td>
                                <td>
                                {encadrementData.matieres && encadrementData.matieres}
                                </td>
                                <td>
                                <Link className='btn btn-primary mt-3' to={`/todo?idEleve=${eleve.id}&idProf=${professeur.id}`} style={{ marginLeft: 12 }}>Evaluer</Link>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="5">Pas de professeur(s)</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Professeurs;