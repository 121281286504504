import React from "react";

const Footer = () => {
  return (
    <footer>
        <div class="footer-wrapper">
            <div class="footer-section f-section-1">
                <p class="">Copyright © <span class="dynamic-year">2024</span> HomeTeacher, Tous droits réservés.</p>
            </div>
        </div>
    </footer>
  );
};

export default Footer;
