import React, { useEffect, useState } from "react";
import './index.css'
import { MenuItem, CircularProgress, Card, CardContent, Typography, List, ListItem, ListItemText, Divider, Grid } from "@mui/material";
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom";
import { useUser } from '../../utils/UserContext';
import config from '../../utils/config'
  
const Dashboard = () => {

  const [NbrSeancesManquees, setNbrSeancesManquees] = useState(null)
  const [loadingNbrSeancesManquees, setLoadingNbrSeancesManquees] = useState(true)
  const [selectedStudentAgain, setSelectedStudentAgain] = useState("");
  const [selectedProf, setSelectedProf] = useState("");
  const [showListProfs, setShowListProfs] = useState(false)
  const [eleve, setEleve] = useState(null)
  const [eleves, setEleves] = useState([])
  const [loadingEleves, setLoadingEleves] = useState(true)
  const { userInfos } = useUser()


  useEffect(() => {
    // Importez CryptoJS si vous l'avez installé via npm
      const CryptoJS = require('crypto-js');
      const urlParams = new URLSearchParams(window.location.search);
      const encryptedData = urlParams.get('data');
      const encryptedData2 = urlParams.get('logout');
      if (encryptedData2 === 'yes') {
        localStorage.removeItem("user")
        window.location.href = config.client
      }

      if (encryptedData) {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, 'R#i$5&@9lP!s2*');
        const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
        localStorage.setItem("user", decryptedData)
        window.location.href = config.clientAdmin
      } else {
        console.error('Aucune donnée chiffrée trouvée');
      }
  }, [])


  const getNbrSeancesManquees = async (idProf, idEleve) => {
    try {
      setLoadingNbrSeancesManquees(true);
      
      if (idProf && idEleve) {
        const url = `${config.apiUrl}/encadrement/get_seances_manquees/${idProf}/${idEleve}`;
        
        const response = await fetch(url);
        
        // Vérifier si la réponse est OK (status 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const seances_manquees = await response.json();
        setNbrSeancesManquees(seances_manquees.data.length);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des séances manquées:", error);
      // Tu peux gérer l'erreur ici, par exemple en affichant un message d'erreur à l'utilisateur
    } finally {
      setLoadingNbrSeancesManquees(false);
    }
  };
  

  const fetchElevesData = async () => {
    const eleves_staging = [];
    setLoadingEleves(true)
    if(userInfos){
      const enfants = userInfos.typeCompte === "Professeur" ? userInfos.ideleves : userInfos.idEleves
      for(const ideleve of enfants) {
        const url = `${config.apiUrl}/comptes/eleves/${ideleve}/`;

        try {
            const response = await fetch(url, {
                method: "GET",
            });
            const data = await response.json();

            eleves_staging.push(data);

        } catch(error) {
            console.log("Erreur => ", error);
        }
    }

    // Mettez à jour le state une fois que toutes les données ont été récupérées
    setEleves(eleves_staging);
    setLoadingEleves(false)
     
    }
};



  useEffect(() => {
    
    fetchElevesData();  
    // Clean up function to destroy charts when component unmounts
  }, [userInfos]);


  const fetchEleve = async () => {
    const url = `${config.apiUrl}/comptes/eleves/${selectedStudentAgain}/`;

    const response = await fetch(url);
    const data = await response.json();
    
    if (data.professeurs.length === 0) {
        setNbrSeancesManquees(null);
    } else {
        // Attendre la résolution de la promesse ici
        await getNbrSeancesManquees(selectedProf, data.id);
    }
    setEleve(data);
};



  useEffect(() => {
    if(selectedProf !== "" && userInfos.typeCompte === 'Tuteur'){
        getNbrSeancesManquees(selectedProf , selectedStudentAgain)
    }
  }, [selectedProf])

  useEffect(() => {
    if(selectedStudentAgain !== "" && userInfos){
        if(userInfos.typeCompte === "Tuteur"){
            fetchEleve()
            setShowListProfs(true)
        }else{
          getNbrSeancesManquees(userInfos.id, selectedStudentAgain)
        }
    }
  }, [selectedStudentAgain])



  return (
      <div className="container" style={{marginTop: 50}}>
      <div className="row">
          <div className="col-md-12">
              <Link to={`${userInfos && (userInfos.typeCompte === "Professeur" ? '/eleves' : '/professeurs')}`}>
                <div className="shadow p-3 mb-5 bg-white rounded" style={{ cursor: 'pointer' }}>
                    {
                    userInfos &&
                    (
                      userInfos.typeCompte === "Professeur" ?
                      <>
                      <h5>Effectifs des élèves encadrés</h5>
                      <h4 style={{ textAlign: 'center' }}>{userInfos.ideleves.length}</h4>
                      </>
                      :
                      <>
                      <h5>Nombre total d'enfants</h5> 
                      <h4 style={{ textAlign: 'center' }}>{userInfos.idEleves.length}</h4>
                      </>
                    )
                    }
                </div>
              </Link>
          </div>
      </div>

        <div className="col-12">
            {/* <Link to={userInfos && userInfos.typeCompte === "Professeur" ? "/eleves" : "/professeurs"}> */}
            <>
            <div className="shadow p-3 mb-5 bg-white rounded">
                <h5>Nombre de séances annulés</h5>
                
                <TextField
                    select
                    label="Sélectionnez l'élève"
                    variant="outlined"
                    fullWidth
                    sx={{mb: 7, mt: 3}}
                    value={selectedStudentAgain}
                    onChange={(e) => setSelectedStudentAgain(e.target.value)}
                >
                    {eleves && eleves.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.nom} {option.prenom}
                    </MenuItem>
                    ))}
                </TextField>

                {
                  showListProfs &&
                  <TextField
                      select
                      label="Sélectionnez le professeur concerné"
                      variant="outlined"
                      fullWidth
                      sx={{mb: 3, mt: -3}}
                      value={selectedProf}
                      onChange={(e) => setSelectedProf(e.target.value)}
                  >
                      {eleve && Array.isArray(eleve.professeurs) && eleve.professeurs.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                          {option.last_name} {option.first_name} 
                      </MenuItem>
                      ))}
                  </TextField>
                }
                
                {
                  selectedStudentAgain !== "" && (userInfos.typeCompte === "Tuteur" ? selectedProf !== "" : true) &&
                  (
                      loadingNbrSeancesManquees ?
                      <center>
                          <CircularProgress size={20} />
                      </center> :
                      <h4 style={{ marginTop: -10, textAlign: 'center' }}>
                        {
                          NbrSeancesManquees
                        }
                      </h4>
                  )                
                }

            </div>
            </>
        </div>
      </div>

  );
};

export default Dashboard;
