import React, { createContext, useContext, useEffect, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userInfos, setUserInfos] = useState(null);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem('user')
    if(user){
      setUserInfos(JSON.parse(user))
    }
  }, [])

  useEffect(() => {
    if(userInfos){
      setChanged(true)
    }
  }, [userInfos])

  return (
    <UserContext.Provider value={{ userInfos , setUserInfos, changed, setChanged }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
