import React, { useEffect, useState } from 'react'
import { useUser } from '../../utils/UserContext';
import config from '../../utils/config'
import { CircularProgress } from '@mui/material';

const getAge = (date) => {
  // Supposons que dateDonnee soit la date que vous avez et que vous voulez calculer la différence
  const dateDonnee = new Date(date); // Exemple de date

  const dateActuelle = new Date(); // Récupérer la date actuelle

  const differenceEnMilliseconds = dateActuelle - dateDonnee;
  const differenceEnAnnees = differenceEnMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // On utilise 365.25 jours pour tenir compte des années bissextiles

  const age = Math.floor(differenceEnAnnees);

  return age // Cela recuperera l'âge en années

}

function Eleves() {
  const [isLoading, setIsLoading] = useState(true)
  const [eleves, setEleves] = useState(null)
  const { userInfos  } = useUser()
  
    useEffect(() => {
        if(userInfos){
            const fetchElevesData = async () => {
                const eleves_staging = [];
                for(const ideleve of userInfos.ideleves) {
                    let url = `${config.apiUrl}/comptes/eleves/${ideleve}/`;
            
                    try {
                        const response = await fetch(url, {
                            method: "GET",
                        });
                        const data = await response.json();
                        url = `${config.apiUrl}/comptes/get_encadrement/${ideleve}/${userInfos.id}/`;
                        const encadrementResponse = await fetch(url);
                        const encadrementData = await encadrementResponse.json();
                        data.jours_travail =  encadrementData.data.jours_travail                 
                        data.matieres =  encadrementData.data.matieres   
                        

                        eleves_staging.push(data);

                    } catch(error) {
                        console.log("Erreur => ", error);
                    }
                }

                // Mettez à jour le state une fois que toutes les données ont été récupérées
                setEleves(eleves_staging);
                setIsLoading(false)
            };

            fetchElevesData();
        }
    }, [userInfos]);

  return (
<div className="row layout-spacing layout-top-spacing" id="cancel-row">
  <h3 className='text-center'>Liste des élèves que vous encadrez</h3>
  
  {
    isLoading ?
    <>
    <center>
      <CircularProgress sx={{mt: 20}} size={50} />
    </center>
    </>
    :
    <div className="col-lg-12">
    <div className="widget-content searchable-container list">
      <div className="searchable-items list">
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className='text-center'>
              <tr>
                <th>Identifiants</th>
                <th>Age</th>
                <th>Genre</th>
                <th>Classe</th>
                <th>Jours et heures de travail</th>
                <th>Matières</th>
              </tr>
            </thead>
            <tbody className='text-center'>
              {eleves && Array.isArray(eleves) && eleves.map((eleve) => (
                <tr key={eleve.id}>
                <td>
                <center>
                <div className="user-profile">
                    <img
                    src={config.apiUrl+ eleve.photo}
                    alt="avatar"
                    width={100}
                    height={100}
                    className="img-thumbnail" // Ajout de la classe img-thumbnail pour réduire la taille de l'image
                    />
                    <div className="user-meta-info">
                    <p className="user-name" data-name="Alan Green">{eleve.nom}</p>
                    <p className="user-work" data-occupation="Web Developer">{eleve.prenom}</p>
                    </div>
                </div>
                </center>
                </td>

                  <td>{getAge(eleve.date_naissance)+" ans"}</td>
                  <td>{eleve.sexe}</td>
                  <td>{eleve.classe}</td>
                  <td>
                    {
                      eleve.jours_travail &&

                      Object.entries(eleve.jours_travail).map(([jour, heures]) => (
                          <p key={jour}>{jour} : {heures}</p>
                      ))
                    }
                  
                  </td>
                  <td>
                    {eleve.matieres && eleve.matieres}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  }
</div>

  )
}

export default Eleves