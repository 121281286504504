import React from 'react'
import Dashboard from '../../Pages/DashBoard'
import { Routes, Route } from "react-router-dom";
import Profile from "../../Pages/Profile";
import Ressources from "../../Pages/Ressources";
import EditProfile from '../../Pages/EditProfile';
import EditProfileEleve from '../../Pages/EditProfileEleve';
import Professeurs from '../../Pages/Professeurs';
import Eleves from '../../Pages/Eleves';
import Todo from '../../Pages/Todo';
import Evaluations from '../../Pages/Evaluations';
import EmploiDuTemps from '../../Pages/EmploiDuTemps';
import NotFound from '../NotFound';
import Notifications from '../../Pages/Notifications';
import Paiement from '../../Pages/Paiement';


function AppRoutes() {
  return (
    <>
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/ressources" element={<Ressources />} />
            <Route path="/editProfile" element={<EditProfile />} />
            <Route path="/editProfileEleve" element={<EditProfileEleve />} />
            <Route path="/professeurs" element={<Professeurs />} />
            <Route path="/eleves" element={<Eleves />} />
            <Route path="/todo" element={<Todo />} />
            <Route path="/evaluations" element={<Evaluations />} />
            <Route path="/emploidutemps" element={<EmploiDuTemps />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/paiement" element={<Paiement />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
        
    </>
  )
}

export default AppRoutes