import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../utils/UserContext";
import logoDark from "../../assets/img/logoDark.svg"


const NavBarLeft = () => {
    const location = useLocation()
    const navigate = useNavigate();

    const { userInfos } = useUser()
    const [showSideBar, setShowSideBar] = useState(true)

    useEffect(() => {
        const route = location.pathname.slice(1)
        if(route == "Todo"){
         
         navigate(`/${route}`);   
        }
    }, [])
    
    useEffect(() => {

            let main = document.querySelector(".main-container")
            let overlay = document.querySelector(".overlay")
            main.classList.add("sidebar-closed")
            main.classList.add("sbar-open")
            overlay.classList.remove("show")                  

    }, [showSideBar])

    useEffect(() => {
        const route = location.pathname.slice(1)
        console.log(route)
        let active = document.querySelector(".active")
        let nowActive = document.querySelector(`.${route === "" ? "dashboard": route}`)
        

        if(active && nowActive){
            active.classList.remove("active")
            nowActive.classList.add("active")
        }
    }, [location])

  return (
        <div className="sidebar-wrapper sidebar-theme">
          <nav id="sidebar">
            <div className="navbar-nav theme-brand flex-row  text-center">
                <div className="nav-logo">
                <div className="nav-item theme-logo">
                    <Link to={"/"}>
                        <img src={logoDark} style={{width: "200px",height: "80px"}} className="navbar-logo" alt="logo" />
                    </Link>
                </div>
                </div>
                <div className="nav-item sidebar-toggle">
                    <div className="btn-toggle sidebarCollapse">
                        <svg xmlns="http://www.w3.org/2000/svg" onClick={e => setShowSideBar(!showSideBar)} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevrons-left"><polyline points="11 17 6 12 11 7"></polyline><polyline points="18 17 13 12 18 7"></polyline></svg>
                    </div>
                </div>
            </div>

            <ul className="list-unstyled menu-categories" id="accordionExample">
                    <li className="menu active dashboard">
                        <Link to={"/"} className="dropdown-toggle">
                            <div className="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                <span>Tableau de bord</span>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                            </div>
                        </Link>
                        <ul className="collapse submenu list-unstyled show" id="dashboard" data-bs-parent="#accordionExample">
                            
                        </ul>
                    </li>

                    <li className="menu menu-heading">
                        <div className="heading"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-minus"><line x1="5" y1="12" x2="19" y2="12"></line></svg><span>APPLICATIONS</span></div>
                    </li>

                    {/* <li className="menu ressources">
                        <Link to={"/ressources"} aria-expanded="false" className="dropdown-toggle">
                            <div className="">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path fill="#ffffff" d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>

                                <span>Ressources</span>
                            </div>
                        </Link>
                    </li> */}

                    {
                        userInfos && userInfos.typeCompte === "Tuteur" &&
                        <>
                        <li className="menu professeurs">
                            <Link to={"/professeurs"} aria-expanded="false" className="dropdown-toggle">
                                <div className="">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path fill="#ffffff" d="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H384V320c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v32h64V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm0 64a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM133.3 352h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z"/></svg>

                                    <span>Professeurs <br /> de vos enfants</span>
                                </div>
                            </Link>
                        </li>
                        </>
                    }

                    {
                        userInfos && userInfos.typeCompte === "Professeur" &&
                        <>
                        <li className="menu eleves">
                            <Link to={"/eleves"} aria-expanded="false" className="dropdown-toggle">
                                <div className="">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path fill="#ffffff" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>

                                    <span>Elève(s) que <br /> vous encadrez</span>
                                </div>
                            </Link>
                        </li>
                        {/* <li className="menu todo">
                            <Link to={"/todo"} aria-expanded="false" className="dropdown-toggle">
                                <div className="">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="1em" viewBox="0 0 512 512"><path fill="#ffffff" d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>

                                    <span>Tâches</span>
                                </div>
                            </Link>
                        </li> */}
                        </>
                    }

                    {
                        userInfos &&
                        <>
                        <li className="menu emploidutemps">
                            <Link to={"/emploidutemps"} aria-expanded="false" className="dropdown-toggle">
                                <div className="">
                                <svg width="300px" height="300px" viewBox="0 0 24.00 24.00" fill="#ffffff" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffff" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#000000" stroke-width="0.8399999999999999" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 6V12" stroke="#000000" stroke-width="0.8399999999999999" stroke-linecap="round" stroke-linejoin="round"></path> <path fill="#ffffff" d="M16.24 16.24L12 12" stroke="#000000" stroke-width="0.8399999999999999" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                    <span>Emploi du temps</span>
                                </div>
                            </Link>
                        </li>
                        <li className="menu evaluations">
                            <Link to={"/evaluations"} aria-expanded="false" className="dropdown-toggle">
                                <div className="">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path fill="#ffffff" d="M416 0c17.7 0 32 14.3 32 32c0 59.8-30.3 107.5-69.4 146.6c-28 28-62.5 53.5-97.3 77.4l-2.5 1.7c-11.9 8.1-23.8 16.1-35.5 23.9l0 0 0 0 0 0-1.6 1c-6 4-11.9 7.9-17.8 11.9c-20.9 14-40.8 27.7-59.3 41.5H283.3c-9.8-7.4-20.1-14.7-30.7-22.1l7-4.7 3-2c15.1-10.1 30.9-20.6 46.7-31.6c25 18.1 48.9 37.3 69.4 57.7C417.7 372.5 448 420.2 448 480c0 17.7-14.3 32-32 32s-32-14.3-32-32H64c0 17.7-14.3 32-32 32s-32-14.3-32-32c0-59.8 30.3-107.5 69.4-146.6c28-28 62.5-53.5 97.3-77.4c-34.8-23.9-69.3-49.3-97.3-77.4C30.3 139.5 0 91.8 0 32C0 14.3 14.3 0 32 0S64 14.3 64 32H384c0-17.7 14.3-32 32-32zM338.6 384H109.4c-10.1 10.6-18.6 21.3-25.5 32H364.1c-6.8-10.7-15.3-21.4-25.5-32zM109.4 128H338.6c10.1-10.7 18.6-21.3 25.5-32H83.9c6.8 10.7 15.3 21.3 25.5 32zm55.4 48c18.4 13.8 38.4 27.5 59.3 41.5c20.9-14 40.8-27.7 59.3-41.5H164.7z"/></svg>

                                    <span>Evaluations</span>
                                </div>
                            </Link>
                        </li>
                        {/* {
                            userInfos && userInfos.typeCompte === "Tuteur" &&
                            <li className="menu paiement">
                                <Link to={"/paiement"} aria-expanded="false" className="dropdown-toggle">
                                    <div className="">
                                    <svg viewBox="0 0 24 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="2"></circle> <path d="M15 9.94728C14.5 9.3 13.8 8.5 12 8.5C10.2 8.5 9 9.51393 9 9.94728C9 10.3806 9.06786 10.9277 10 11.5C10.7522 11.9618 12.6684 12.0439 13.5 12.5C14.679 13.1467 14.8497 13.8202 14.8497 14.0522C14.8497 14.6837 13.4175 15.4852 12 15.5C10.536 15.5153 9.5 14.7 9 14.0522" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 7V17" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>

                                        <span>Paiement</span>
                                    </div>
                                </Link>
                            </li>
                        } */}
                        <li className="menu profile">
                            <Link to={"/profile"} aria-expanded="false" className="dropdown-toggle">
                                <div className="">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path fill="#ffffff" d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zm96 320h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H96c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM144 64h96c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>

                                    <span>Profile</span>
                                </div>
                            </Link>
                        </li>
                        </>
                    }

                </ul>
          </nav>
        </div>
  );
};

export default NavBarLeft;
