import React, { useEffect, useState } from 'react'
import { useUser } from '../../utils/UserContext';
import { CircularProgress } from '@mui/material';
import config from '../../utils/config'

function Ressources() {

  const [ressources, setRessources] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { userInfos  } = useUser()



useEffect(() => {


    setIsLoading(true)
    const url = `${config.apiUrl}/encadrement/ressources/`;

    fetch(
      `${url}`,
      {
        method: "GET",
      }
    )
    .then((response) => response.json())
    .then((data) => {
      setRessources(data)
      setIsLoading(false)
    })
    .catch((error) => {
      console.log("ERREUR => ", error)
      setIsLoading(false)
    });

    let script = document.createElement('script');
    script.src = 'assets/js/apps/contact.js';
    document.head.appendChild(script);

}, [])




  return (
          <div className="row layout-spacing layout-top-spacing" id="cancel-row">
              <div className="col-lg-12">
                  <div className="widget-content searchable-container list">
                      <div className="searchable-items list">
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Titre</th>
                                <th>Description</th>
                                <th>Classe</th>
                                <th>Matiere</th>
                                <th>Contenu</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                              isLoading ? <center><CircularProgress sx={{mt: 20}} size={50} /></center> :
                              (userInfos &&
                                ressources &&
                                Array.isArray(ressources) &&
                                ressources.map((ressource) => {
                                  const isTuteurAccessible =
                                    userInfos.typeCompte === "Tuteur" && ressource.tuteur_accessible;
                                  const isProfesseurAccessible =
                                    userInfos.typeCompte === "Professeur" && ressource.professeur_accessible;

                                  if (isTuteurAccessible || isProfesseurAccessible) {
                                    return (
                                      <tr key={ressource.id}>
                                        <td>{ressource.titre}</td>
                                        <td>{ressource.description}</td>
                                        <td>{ressource.classe}</td>
                                        <td>{ressource.matiere}</td>
                                        <td>
                                          <a
                                            href={config.apiUrl+ressource.contenu}
                                            download
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Télécharger
                                          </a>
                                        </td>
                                      </tr>
                                    );
                                  }
                                  return null; // Ignorer les ressources non accessibles
                                }))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                  </div>
              </div>
          </div>
  )
}

export default Ressources