import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, TextField, CircularProgress } from '@mui/material';
import { green, red, orange } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import config from '../../../utils/config'
import mtn from '../../../assets/img/mtn.png'
import airtel from '../../../assets/img/airtel.png'
import './index.css'

function HistoriquePayment() {
  // Exemple de données de transactions
  const [payments, setPayments] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedProf, setSelectedProf] = useState("");
  const [showListProfs, setShowListProfs] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const [eleve, setEleve] = useState(null)
  const [eleves, setEleves] = useState([])

  const userInfos = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if(selectedProf !== "" && userInfos.typeCompte === 'Tuteur'){
        setIsLoading(true)
        fetch(`${config.apiUrl}/encadrement/payments/${selectedStudent}/${selectedProf}/`)
        .then(res => res.json())
        .then(data => {
            setPayments(data.data)
        })
        setIsLoading(false)
    }
  }, [selectedProf])

  useEffect(() => {
    if(selectedStudent !== "" && userInfos){
        if(userInfos.typeCompte === "Tuteur"){
            fetchEleve()
            setShowListProfs(true)
        }
    }
  }, [selectedStudent])

  
  const fetchEleve = async () => {
    const url = `${config.apiUrl}/comptes/eleves/${selectedStudent}/`;

    const response = await fetch(url)
    const data = await response.json()
    setEleve(data)
  }

  const fetchElevesData = async () => {
    const eleves_staging = [];
    if(userInfos){
        const enfants = userInfos.idEleves
        for(const ideleve of enfants) {
            const url = `${config.apiUrl}/comptes/eleves/${ideleve}/`;

            try {
                const response = await fetch(url, {
                    method: "GET",
                });
                const data = await response.json();

                eleves_staging.push(data);

            } catch(error) {
                console.log("Erreur => ", error);
            }
        }

        // Mettez à jour le state une fois que toutes les données ont été récupérées
        setEleves(eleves_staging);        
        }
    };


  useEffect(() => {
    fetchElevesData();
  }, [])


  // Fonction pour obtenir l'icône de statut appropriée en fonction du statut de la transaction
  const getStatusIcon = (status) => {
    switch (status) {
      case 'pending':
        return <HourglassEmptyIcon sx={{ color: orange[500] }} />;
      case 'failed':
        return <ErrorIcon sx={{ color: red[500] }} />;
      case 'success':
        return <CheckCircleIcon sx={{ color: green[500] }} />;
      default:
        return null;
    }
  };

  return (
    <div>
        <TextField
            select
            label="Sélectionnez l'élève"
            variant="outlined"
            fullWidth
            sx={{mb: 7, mt: 3}}
            value={selectedStudent}
            onChange={(e) => setSelectedStudent(e.target.value)}
        >
            {eleves && eleves.map((option) => (
            <MenuItem key={option.id} value={option.id}>
                {option.nom} {option.prenom}
            </MenuItem>
            ))}
        </TextField>

        {
        showListProfs &&
        <TextField
            select
            label="Sélectionnez le professeur concerné"
            variant="outlined"
            fullWidth
            sx={{mb: 3, mt: -3}}
            value={selectedProf}
            onChange={(e) => setSelectedProf(e.target.value)}
        >
            {eleve && Array.isArray(eleve.professeurs) && eleve.professeurs.map((option) => (
            <MenuItem key={option.id} value={option.id}>
                {option.last_name} {option.first_name} 
            </MenuItem>
            ))}
        </TextField>
        }

        {
            payments.length > 0 ?
            <TableContainer component={Paper}>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell className='head'>Date</TableCell>
                    <TableCell className='head'>Frais mensuels</TableCell>
                    <TableCell className='head'>Téléphone</TableCell>
                    <TableCell className='head'>Mode de paiement</TableCell>
                    <TableCell className='head'>Note</TableCell>
                    <TableCell className='head'>Statut</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {payments.map((transaction) => (
                    <TableRow key={transaction.id}>
                    <TableCell className='data'>{new Date(transaction.updated_at).toLocaleString('fr-FR', { timeZone: 'UTC' })}</TableCell>
                    <TableCell className='data'>{transaction.encadrement.frais_mensuels} FCFA</TableCell>
                    <TableCell className='data'>{transaction.phone}</TableCell>
                    <TableCell className='data'><img src={transaction.payment_method == 'cg_mm' ? mtn : airtel} style={{width: 50, height: '100%'}} /></TableCell>
                    <TableCell className='data'>{transaction.note}</TableCell>
                    <TableCell className='data'>
                        {getStatusIcon(transaction.status)} {transaction.status}
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>:
            <center>
                <h4 className='mt-4'>Pas de transactions pour l'instant</h4>
            </center>
        }

    </div>
  );
}

export default HistoriquePayment;
