import { useState, useEffect } from 'react'
import InputCustom from '../../InputCustom'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Step2 = (props) => {
    const { nom , setNom, prenom, setPrenom, dateNaissance, setDateNaissance, setShowBtn, setError, ecole, setEcole, sexe, setSexe } = props

    const [msgNom, setMsgNom] = useState('')
    const [msgPrenom, setMsgPrenom] = useState('')
    const [msgDateNaissance, setMsgDateNaissance] = useState('')
    const [msgEcole, setMsgEcole] = useState('')
    const [msgSexe, setMsgSexe] = useState('')
    
    useEffect(() => {
      let annee = new Date(dateNaissance).getFullYear();
        if(nom !== '' && sexe !== '' && prenom !== '' && dateNaissance !== '' && annee >= 1993 && annee <= 2019 && ecole !== ''){
          setShowBtn(true)
        }else{
          setShowBtn(false)
        }
        // eslint-disable-next-line
      }, [nom,prenom, dateNaissance, ecole,sexe])
    
      useEffect(() => {
        if(nom === ''){
          setMsgNom("Le nom est obligatoire")
        }else{
          setMsgNom('')
        }
      }, [nom])

      useEffect(() => {
        if(sexe === ''){
          setMsgSexe("Le sexe est obligatoire")
        }else{
          setMsgSexe('')
        }
      }, [sexe])

      useEffect(() => {
        if(ecole === ''){
          setMsgEcole("L'ecole est obligatoire")
        }else{
          setMsgEcole('')
        }
      }, [ecole])

      useEffect(() => {
        if(prenom === ''){
          setMsgPrenom("Le prenom est obligatoire")
        }else{
          setMsgPrenom('')
        }
      }, [prenom])

      useEffect(() => {
        if(dateNaissance === ''){
          setMsgDateNaissance("La date de naissance est obligatoire")
        }else{
          let annee = new Date(dateNaissance).getFullYear();
          if (annee >= 1993 && annee <= 2019) {
            setMsgDateNaissance('')
          } else {
            setMsgDateNaissance("L'âge doit être comprise entre 4 et 30 ans");
          }
          
        }
      }, [dateNaissance])

      useEffect(() => {
        setMsgNom('')
        setMsgPrenom('')
        setMsgDateNaissance('')
        setMsgEcole('')
        setMsgSexe('')
        setShowBtn(true);  
        setError('')
        // eslint-disable-next-line
    }, [])


    return (
        <div className='text-center mt-4'>
            <InputCustom
              type="text"
              label="Nom(s) de l'élève"
              placeholder="Ex : NTSOUMOU"
              value={nom}
              change={setNom}
            />
            <p style={{ color: "red",textAlign: 'center' }}>{msgNom}</p>
            <InputCustom
              type="text"
              label="Prénom(s) de l'élève"
              placeholder="Ex : Mondesir"
              value={prenom}
              change={setPrenom}
            />
            <p style={{ color: "red",textAlign: 'center' }}>{msgPrenom}</p>            
            <InputCustom
              type="date"
              label="Date de naissance"
              placeholder="Date de naissance"
              value={dateNaissance}
              change={setDateNaissance}
            />
            <p style={{ color: "red",textAlign: 'center' }}>{msgDateNaissance}</p>
            <FormControl sx={{ m: 1, width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Genre de l'élève</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sexe}
                label="Genre de l'élève"
                onChange={e => setSexe(e.target.value)}
              >
                <MenuItem value="Masculin">Masculin</MenuItem>
                <MenuItem value="Feminin">Feminin</MenuItem>
              </Select>
            </FormControl>
            <p style={{ color: "red",textAlign: 'center' }}>{msgSexe}</p>
            <InputCustom
              label="Dans quelle école fréquente t-il ?"
              type="text"
              placeholder="Ex : Lumumba"
              value={ecole}
              change={setEcole}
            />
            <p style={{ color: "red",textAlign: 'center' }}>{msgEcole}</p>
        </div>
    )
}

export default Step2