import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useUser } from '../../utils/UserContext'
import { CircularProgress } from '@mui/material'
import config from '../../utils/config'

function Profile() {

  const { userInfos } = useUser()
  const [eleves, setEleves] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    if(userInfos && userInfos.typeCompte === "Tuteur"){
        const fetchElevesData = async () => {
            setIsLoading(true)
            const eleves_staging = [];
            for(const ideleve of userInfos.idEleves) {
                const url = `${config.apiUrl}/comptes/eleves/${ideleve}/`;

                try {
                    const response = await fetch(url, {
                        method: "GET",
                    });
                    const data = await response.json();
                    console.log("adta" , data)
                    eleves_staging.push(data);
                } catch(error) {
                    console.log("Erreur => ", error);
                }
            }

            // Mettez à jour le state une fois que toutes les données ont été récupérées
            setEleves(eleves_staging);
            setIsLoading(false)
        };

        fetchElevesData();
    }
    }, [userInfos]);

  const getAge = (date) => {
    // Supposons que dateDonnee soit la date que vous avez et que vous voulez calculer la différence
    const dateDonnee = new Date(date); // Exemple de date

    const dateActuelle = new Date(); // Récupérer la date actuelle

    const differenceEnMilliseconds = dateActuelle - dateDonnee;
    const differenceEnAnnees = differenceEnMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // On utilise 365.25 jours pour tenir compte des années bissextiles

    const age = Math.floor(differenceEnAnnees);

    return age // Cela recuperera l'âge en années

  }


  return (
    <>
        {
            userInfos &&

        <div className="row layout-spacing ">
            <center>
            <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
                <div className="user-profile">
                    <div className="widget-content widget-content-area">
                        <div className="d-flex justify-content-between">
                            <h3 className="">Votre Profile 😊</h3>
                            <Link to={"/editProfile"} className="mt-2 edit-profile"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg></Link>
                        </div>
                        <div className="text-center user-info">
                            <img src={config.apiUrl+userInfos.photo} alt="avatar" />
                            <p className="">{userInfos.last_name} {userInfos.first_name}</p>
                        </div>
                        <div className="user-info-list">

                            <div className="">
                                <ul className="contacts-block list-unstyled">
                                    {
                                        userInfos.typeCompte === "Tuteur" &&
                                        <>
                                            <li className="contacts-block__item">
                                                - Total des enfants : {userInfos.idEleves.length}
                                            </li>
                                            <li className="contacts-block__item">
                                                - Profession : {userInfos.profession}
                                            </li>
                                            <li className="contacts-block__item">
                                                - Ville : {userInfos.ville}
                                            </li>
                                            <li className="contacts-block__item">
                                                <a href="mailto:example@mail.com">- Email : {userInfos.email}</a>
                                            </li>
                                            <li className="contacts-block__item">
                                                - Telephone : {userInfos.telephone1} , {userInfos.telephone2}
                                            </li> 
                                        </>
                                    }           
                                    {
                                        userInfos.typeCompte === "Professeur" &&
                                        <>
                                            <li className="contacts-block__item">
                                                - Total des élèves encadrés : {userInfos.ideleves.length}
                                            </li>
                                            <li className="contacts-block__item">
                                                - Age : {getAge(userInfos.date_naissance)} ans
                                            </li>    
                                            <li className="contacts-block__item">
                                                - Ville : {userInfos.ville}
                                            </li>
                                            <li className="contacts-block__item">
                                                - Adresse : {userInfos.adresse}
                                            </li>
                                            <li className="contacts-block__item">
                                                <a href="mailto:example@mail.com">- Email : {userInfos.email}</a>
                                            </li>
                                            <li className="contacts-block__item">
                                                - Telephone : {userInfos.telephone1} , {userInfos.telephone2}
                                            </li>
                                        </>
                                    }
                                </ul>
                            </div>                                    
                        </div>
                    </div>
                </div>
            </div>
            </center>
            {

            userInfos && (userInfos.typeCompte === "Tuteur" ) && 
            isLoading ? <center><CircularProgress sx={{mt: 20}} size={50} /></center> :
            (eleves.map(eleve => {
                            return (
                                <div key={eleve.id} className="col-xl-5 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
                                    <div className="user-profile">
                                        <div className="widget-content widget-content-area">
                                        <h6 className='text-center'>Vous pouvez modifier les informations de votre enfant</h6>
                                            <div className="d-flex justify-content-between">
                                                <h3 className=""></h3>
                                                <Link to={`/editProfileEleve?idEleve=${eleve.id}`} className="mt-2 edit-profile"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg></Link>
                                            </div>
                                            <div className="text-center user-info">
                                                <img src={config.apiUrl+eleve.photo} alt="avatar" />
                                                <p className="">{eleve.nom} {eleve.prenom}</p>
                                            </div>
                                            <div className="user-info-list">

                                                <div className="">
                                                    <ul className="contacts-block list-unstyled">
                                                        <li className="contacts-block__item">
                                                            - Classe : {eleve.classe}
                                                        </li>
                                                        <li className="contacts-block__item">
                                                            - Ville : {eleve.ville}
                                                        </li>
                                                        <li className="contacts-block__item">
                                                            - Matiere : {JSON.parse(eleve.matieres)[eleve.classe].join(",")}
                                                        </li>
                                                        <li className="contacts-block__item">
                                                            - Age : {getAge(eleve.date_naissance)} ans
                                                        </li>
                                                    </ul>
                                                </div>                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }))
                    }
        </div>
        }
    </>
  )
}

export default Profile