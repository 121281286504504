import { useState, useEffect } from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { TextField, Box } from "@mui/material";
import config from '../../../utils/config'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const validerHeures = (disponibilites) => {
  for (const jour in disponibilites) {
    const { heureDebut, heureFin } = disponibilites[jour];
    if (!heureDebut || !heureFin) {
      return false;
    }
  }
  return true;
};


const validerMatieres = (matieres) => {
  for (const matiere in matieres) {
    if (matieres[matiere].length === 0) {
      return false;
    }
  }
  return true;
};

const classesGeneralesPrimaire = [
  'CP1',
  'CP2',
  'CE1',
  'CE2',
  'CM1',
  'CM2'
];

const classesGeneralesCollege = [
  '6eme',
  '5eme',
  '4eme',
  '3eme',
];

const classesGeneralesLyceeA = [
  'Première A',
  'Terminale A'
];
const classesGeneralesLyceeD = [
  'Première D',
  'Terminale D',
];
const classesGeneralesLyceeC = [
  'Première C',
  'Terminale C',
];

const matieresGeneralesPrimaire = [
  'Mathematiques',
  'Francais',
  'Question de cours'
];

const matieresGeneralesCollege = [
  'Mathematiques',
  'Anglais',
  'Francais',
  'Physique-Chimie',
  'Svt'
];

const matieresGeneralesLyceeA = [
  'Mathematiques',
  'Anglais',
  'Francais',
  'Philosophie'
];

const matieresGeneralesLyceeDC = [
  'Mathematiques',
  'Anglais',
  'Francais',
  'Philosophie',
  'Physique-Chimie',
  'Svt'
];



const jours = [
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
  'Dimanche',
];

const Step3 = (props) => {

  const {
    joursDisponibles,
    setJoursDisponibles,
    classesGeneralesEnseignables,
    setClassesGeneralesEnseignables,
    matieresGeneralesEnseignables,
    setMatieresGeneralesEnseignables,
    periodeJournee,
    setPeriodeJournee,
    pieceJointe,
    setPieceJointe,
    setShowBtn,
    setError,
  } = props;

  const [classeGeneraleName, setClasseGeneraleName] = useState(classesGeneralesEnseignables.split(','));
  const [joursName, setJoursName] = useState(joursDisponibles.split(','));
  const [disponibilites, setDisponibilites] = useState(JSON.parse(periodeJournee));
  
  const [matieres, setMatieres] = useState(JSON.parse(matieresGeneralesEnseignables));
  const [heureSaisie, setHeureSaisie] = useState(true);
  const [matiereSaisie, setMatiereSaisie] = useState(true);

  const handleChange = (e, jour) => {
    const { name, value } = e.target;

    if(value !== ""){

    let updatedValue = value; // Utilisez une variable let pour la valeur
    // Validation de l'heure de début
    if (name === "heureDebut") {
      const heureDebut = value.slice(0, 2);
      const minuteDebut = value.slice(3, 5);
      const heureFin = disponibilites[jour]?.heureFin?.slice(0, 2) || "";
      const minuteFin = disponibilites[jour]?.heureFin?.slice(3, 5) || "";
      if (heureDebut < "07" || heureDebut > "18") {
        // L'heure de début est inférieure à 7h, ajustement à 7h
        updatedValue = "07" + value.slice(2);
      } else if (heureFin && heureDebut > heureFin) {
        let h = Number(heureFin) - 2
        if((h+"").length === 1){
          h = "0"+h          
        }
        updatedValue = h + value.slice(2);
      }else if (heureDebut === heureFin){
        setHeureSaisie(false)
        
        let h = Number(heureDebut) - 2
        if((h+"").length === 1){
          h = "0"+h          
        }
        updatedValue = h +":"+minuteDebut;
        if (minuteDebut > minuteFin){
          updatedValue = heureDebut+":"+minuteFin 
        }
      }else{
        if(heureFin !== "" && minuteFin !== ""){
          const diffHours = heureFin - heureDebut;
          
          if(diffHours === 2){
            disponibilites[jour].heureFin = heureFin+":"+minuteDebut
          }

          if (diffHours < 2) {
            // L'écart entre l'heure de début et l'heure de fin est inférieur à 2 heures
            let h = Number(heureFin) - 2
            if((h+"").length === 1){
              h = "0"+h          
            }
            updatedValue = h +":"+minuteFin;
            // Tu peux également afficher un message d'erreur approprié
            // setError("L'écart entre l'heure de début et l'heure de fin doit être d'au moins 2 heures.");
          }
        }
      }


    }
    

    // Validation de l'heure de fin
    if (name === "heureFin") {
      const heureFin = value.slice(0, 2);
      const minuteFin = value.slice(3, 5);
      const heureDebut = disponibilites[jour]?.heureDebut?.slice(0, 2) || "";
      const minuteDebut = disponibilites[jour]?.heureDebut?.slice(3, 5) || "";

      if (heureFin > "18" || heureFin < "07") {
        // L'heure de fin est supérieure à 18h, ajustement à 18h
        updatedValue = "18" + value.slice(2);
      } else if (heureDebut && heureFin < heureDebut) {
        // L'heure de fin est inférieure à l'heure de début, ajustement à l'heure de début actuelle
        updatedValue = heureDebut + value.slice(2);
      }else if (heureDebut === heureFin){
        let h = Number(heureFin) + 2
        if((h+"").length === 1){
          h = "0"+h          
        }
        updatedValue = h + value.slice(2);
        if (minuteFin < minuteDebut){
          updatedValue = heureFin+":"+minuteDebut 
        }
      }else{
        if(heureDebut !== "" && minuteDebut !== ""){
          const diffHours = heureFin - heureDebut;
          if (diffHours < 2) {
            // L'écart entre l'heure de début et l'heure de fin est inférieur à 2 heures
            let h = Number(heureFin) + 1
            if((h+"").length === 1){
              h = "0"+h          
            }
            updatedValue = h + value.slice(2);
            // Tu peux également afficher un message d'erreur approprié
            // setError("L'écart entre l'heure de début et l'heure de fin doit être d'au moins 2 heures.");
          }
        }
      }
    }

    setDisponibilites((prevState) => ({
      ...prevState,
      [jour]: {
        ...prevState[jour],
        [name]: updatedValue,
      },
    }));

    const updatedHeureSaisie = validerHeures(disponibilites);
    setHeureSaisie(updatedHeureSaisie);

  }
  
  };

  
  useEffect(() => {
    const updatedHeureSaisie = validerHeures(disponibilites);
    setHeureSaisie(updatedHeureSaisie);
  }, [disponibilites])




  const [classeDeleted , setClasseDeleted] = useState([])
  const [classeChecked , setClasseChecked] = useState([])

  const [erreurFormat, setErreurFormat] = useState(false);

  const [msgJoursDisponibles, setMsgJoursDisponibles] = useState("");
  const [msgHeuresSaisies, setMsgHeuresSaisies] = useState("");
  const [msgMatieresSaisies, setMsgMatieresSaisies] = useState("");

  const [msgClassesGeneralesEnseignables, setMsgClassesGeneralesEnseignables] =
    useState("");

  const [msgPieceJointe, setMsgPieceJointe] = useState("");

  const handleChangeClassesGenerales = (event) => {
    const {
      target: { value },
    } = event;
    setClasseGeneraleName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    
    setClasseChecked(value)

    const classesC = classesGeneralesPrimaire.filter((element) => !event.target.value.includes(element));
    setClasseDeleted(classesC)
    

    if(matieres.hasOwnProperty(value[value.length-1])){
      setMatiereSaisie(validerMatieres(matieres))
    }else{
      setMatiereSaisie(false)
    }
    
  };

  useEffect(() => {
    classeDeleted.forEach(classe => {
      delete matieres[classe]
      setMatieres(matieres)
    }) 
  }, [classeDeleted,matieres])

  useEffect(() => {
    classeChecked.forEach(classe => {
      if(matieres.hasOwnProperty(classe)){
        setMatiereSaisie(validerMatieres(matieres))
      }else{
        setMatiereSaisie(false)
      }
    }) 
  }, [classeChecked,matieres])


  useEffect(() => {
    classeChecked.forEach(classe => {
      if(matieres.hasOwnProperty(classe)){
        setMatiereSaisie(validerMatieres(matieres))
      }else{
        setMatiereSaisie(false)
      }
    }) 

  }, [matieres,classeChecked])

  const handleChangeMatieres = (e, classe) => {
    const { value } = e.target;
    setMatieres((prevState) => ({
      ...prevState,
      [classe]: value,
    }));
  };
  

  const handleChangeJours = (e) => {

    const {
      target: { value },
    } = e;
    setJoursName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

    const selectedDays = e.target.value;
    const updatedDisponibilites = {};
    selectedDays.forEach((day) => {
      if (!disponibilites[day]) {
        updatedDisponibilites[day] = {
          heureDebut: "",
          heureFin: "",
        };
      } else {
        updatedDisponibilites[day] = disponibilites[day];
      }
    });
    
    setDisponibilites(updatedDisponibilites);
  };

  useEffect(() => {
    if (
      joursDisponibles !== "" &&
      classesGeneralesEnseignables !== "" &&
      matieresGeneralesEnseignables !== "" &&
      periodeJournee && pieceJointe  && heureSaisie && matiereSaisie
    ) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
    // eslint-disable-next-line
  }, [
    joursDisponibles,
    classesGeneralesEnseignables,
    matieresGeneralesEnseignables,    
    periodeJournee,
    pieceJointe,
    heureSaisie,
    matiereSaisie
  ]);

  useEffect(() => {
    if (joursName.length === 0) {
      setMsgJoursDisponibles("Les jours disponibles sont obligatoires");
    }else if (joursName.length < 2) {
      setMsgJoursDisponibles("Veuillez disposer d'au moins deux jours de disponibles");
    }
    else {
      setMsgJoursDisponibles("");
    }
    setJoursDisponibles(joursName.join(","))
  }, [joursName,setJoursDisponibles]);

  useEffect(() => {
    if(heureSaisie){
      setMsgHeuresSaisies("")
      setPeriodeJournee(JSON.stringify(disponibilites));
    }else{
      if(joursName.length >= 2){
        setMsgHeuresSaisies("Veuillez saisir toutes les heures où vous êtes disponibles")
      }
    }
  }, [heureSaisie,disponibilites,setPeriodeJournee, joursName])

  useEffect(() => {
    if(matiereSaisie){
      setMsgMatieresSaisies("")
      setMatieresGeneralesEnseignables(JSON.stringify(matieres))
    }else{
      setMsgMatieresSaisies("Veuillez saisir toutes les matieres")
    }
  }, [matiereSaisie,matieres,setMatieresGeneralesEnseignables])


  useEffect(() => {
    if (
      classeGeneraleName.length === 0
    ) {
      setMsgClassesGeneralesEnseignables(
        "Les classes generales sont obligatoires"
      );
      setMatiereSaisie(true)
    } else {
      setMsgClassesGeneralesEnseignables("");
    }
    setClassesGeneralesEnseignables(classeGeneraleName.join(","))
  }, [classeGeneraleName,setClassesGeneralesEnseignables]);

  

  useEffect(() => {
    if (pieceJointe === "") {
      setMsgPieceJointe("La piece jointe est obligatoires");
    } else {
      setMsgPieceJointe("");
    }
  }, [pieceJointe]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("my file => ", file)
    if (file) {
      const allowedFormats = ['image/jpeg', 'image/png', 'application/pdf'];
      if (allowedFormats.includes(file.type)) {
        setErreurFormat(false)
        setPieceJointe(file)
      } else {
        setErreurFormat(true)
        setPieceJointe("")
      }
    }
  };

  useEffect(() => {
    if(erreurFormat){
      setMsgPieceJointe('Format de fichier non pris en charge. Veuillez sélectionner une image (JPEG, PNG) ou un document PDF.');
    }else{
      setMsgPieceJointe("");
    }
  }, [erreurFormat])


  useEffect(() => {
    setMsgJoursDisponibles("");
    setMsgClassesGeneralesEnseignables("");
    setMsgPieceJointe("");
    setMsgHeuresSaisies("");
    setMsgMatieresSaisies("");
    setShowBtn(true);  
    setError("");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="text-center mt-4">
        {console.log(" Jours = > ", joursName , " Dispo => ", disponibilites)}
        <FormControl sx={{ m: 1, width: '100%' }}>
          <InputLabel id="demo-multiple-checkbox-label"> Classes que vous pouvez enseigner</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={classeGeneraleName}
            onChange={handleChangeClassesGenerales}
            input={<OutlinedInput label="Classes que vous pouvez enseigner" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            <h6 style={{textAlign: 'center'}}>Primaire</h6>
            {classesGeneralesPrimaire.map((name , index) => (
              <MenuItem value={name} key={index}>
                <Checkbox checked={classeGeneraleName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
            <h6 style={{textAlign: 'center'}}>Collège</h6>
            {classesGeneralesCollege.map((name, index) => (
              <MenuItem value={name} key={index}>
                <Checkbox checked={classeGeneraleName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
            <h6 style={{textAlign: 'center'}}>Lycée série A</h6>
            {classesGeneralesLyceeA.map((name, index) => (
              <MenuItem value={name} key={index}>
                <Checkbox checked={classeGeneraleName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
            <h6 style={{textAlign: 'center'}}>Lycée série D</h6>
            {classesGeneralesLyceeD.map((name, index) => (
              <MenuItem value={name} key={index}>
                <Checkbox checked={classeGeneraleName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
            <h6 style={{textAlign: 'center'}}>Lycée série C</h6>
            {classesGeneralesLyceeC.map((name, index) => (
              <MenuItem value={name} key={index}>
                <Checkbox checked={classeGeneraleName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <p style={{ color: "red" }}>{msgClassesGeneralesEnseignables}</p>

        {classeGeneraleName.map((classe) => (
          <div key={classe}>
            <FormControl sx={{ m: 1, width: '100%' }}>
            <InputLabel id="demo-multiple-checkbox-label">{`Matieres pour ${classe}`} que vous pouvez enseigner</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id={`${classe}-matieres`}
              value={matieres[classe] || []}
              input={<OutlinedInput label={`Matieres pour ${classe} que vous pouvez enseigner`} />}
              onChange={(e) => handleChangeMatieres(e, classe)}
              multiple
            >
            {classesGeneralesPrimaire.includes(classe) && matieresGeneralesPrimaire.map(matiere => (
              <MenuItem key={matiere} value={matiere}>
                {matiere}
              </MenuItem>
            ))}
            {classesGeneralesCollege.includes(classe) && matieresGeneralesCollege.map(matiere => (
              <MenuItem key={matiere} value={matiere}>
                {matiere}
              </MenuItem>
            ))}
            {classesGeneralesLyceeA.includes(classe) && matieresGeneralesLyceeA.map(matiere => (
              <MenuItem key={matiere} value={matiere}>
                {matiere}
              </MenuItem>
            ))}
            {classesGeneralesLyceeD.includes(classe) && matieresGeneralesLyceeDC.map(matiere => (
              <MenuItem key={matiere} value={matiere}>
                {matiere}
              </MenuItem>
            ))}
            {classesGeneralesLyceeC.includes(classe) && matieresGeneralesLyceeDC.map(matiere => (
              <MenuItem key={matiere} value={matiere}>
                {matiere}
              </MenuItem>
            ))}


            </Select>
            </FormControl>
          </div>
        ))}

        <p style={{ color: "red" }}>{msgMatieresSaisies}</p>
  
      <FormControl sx={{ m: 1, width: '100%' }}>
          <InputLabel id="demo-multiple-checkbox-label">Jours disponibles</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={joursName}
            onChange={handleChangeJours}
            input={<OutlinedInput label="Jours disponibles" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {jours.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={joursName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
          
      </FormControl>
      <p style={{ color: "red",textAlign: 'center' }}>{msgJoursDisponibles}</p>
      <p>{joursName.length < 2 ? "":"Vos heures de disponibilité pour chaque jour disponible (heure début et fin), l'heure de travail est comprise entre 07h et 18h"}</p>
      <p style={{fontWeight: 'bold'}}>        
        {joursName.length < 2 ? "":"l'écart entre vos heures dispos doit être au minimum de 2h"}
      </p>
      {joursName.length >=2 && Object.keys(disponibilites).map((jour) => (
        <div key={jour}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            type="time"
            label={`Heure de début (${jour})`}
            id={`${jour}-heureDebut`}
            name="heureDebut"
            value={disponibilites[jour].heureDebut || ""}
            onChange={(e) => handleChange(e, jour)}
            sx={{ m: 1, width: `calc(100% - ${(10 + 14 * jour.length)}px)` }}
          />
          <TextField
            type="time"
            id={`${jour}-heureFin`}
            label={`Heure de fin (${jour})`}
            name="heureFin"
            value={disponibilites[jour].heureFin || ""}
            onChange={(e) => handleChange(e, jour)}
            sx={{ m: 1, width: `calc(100% - ${(10 + 14 * jour.length)}px)` }}
          />
        </Box>
        </div>
      ))}
      <p style={{ color: "red",textAlign: 'center' }}>{msgHeuresSaisies}</p>
      
      <p style={{ textAlign: 'center', marginTop: 10 }}>Pièce d'identité ou cv</p>
      <FormControl sx={{ m: 1, width: '100%' }}>
      <TextField
        type="file"
        name="pieceJointe"
        id="pieceJointe"
        accept=".jpg, .jpeg, .png, .gif, .pdf"
        onChange={handleFileChange}
        error={erreurFormat}
        helperText={erreurFormat}
      />
      {
        typeof pieceJointe === 'string' ?(
        pieceJointe.endsWith('.pdf') ?
        <embed
          src={config.apiUrl+pieceJointe}
          type="application/pdf"
          width="15%"
          height="15%"
        />:
        <img src={config.apiUrl+pieceJointe} alt="Fichier" style={{width:"15%", height:"15%"}} />
        ):
        <h6>Fichier modifié</h6>
      }

      </FormControl>
      <br />
      <p style={{ color: "red" }}>{msgPieceJointe}</p>
    </div>
  );
};

export default Step3;
