import React from 'react'
import SideBar from "../NavBarLeft";
import Content from "../Content";


function Main() {
  return (
    <div className="main-container " id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <Content />
    </div>
  )
}

export default Main