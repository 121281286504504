import { useState, useEffect } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const validerMatieres = (matieres) => {
  for (const matiere in matieres) {
    if (matieres[matiere].length === 0) {
      return false;
    }
  }
  return true;
};

const classesGeneralesPrimaire = [
  'CP1',
  'CP2',
  'CE1',
  'CE2',
  'CM1',
  'CM2'
];

const classesGeneralesCollege = [
  '6eme',
  '5eme',
  '4eme',
  '3eme',
];

const classesGeneralesLyceeA = [
  'Première A',
  'Terminale A'
];
const classesGeneralesLyceeD = [
  'Première D',
  'Terminale D',
];
const classesGeneralesLyceeC = [
  'Première C',
  'Terminale C',
];

const matieresGeneralesPrimaire = [
  'Mathematiques',
  'Francais',
  'Question de cours'
];

const matieresGeneralesCollege = [
  'Mathematiques',
  'Anglais',
  'Francais',
  'Physique-Chimie',
  'Svt'
];

const matieresGeneralesLyceeA = [
  'Mathematiques',
  'Anglais',
  'Francais',
  'Philosophie'
];

const matieresGeneralesLyceeDC = [
  'Mathematiques',
  'Anglais',
  'Francais',
  'Philosophie',
  'Physique-Chimie',
  'Svt'
];






const Step3 = (props) => {
    const { classeGenerale , setClasseGenerale,matieresGenerales,setMatieresGenerales, setShowBtn,setError,setClasse } = props
    const [matiereSaisie, setMatiereSaisie] = useState(true);
    const [matieres, setMatieres] = useState(JSON.parse(matieresGenerales));

    const [msgClasseGenerale, setMsgClasseGenerale] = useState('')
    const [msgMatieresSaisies, setMsgMatieresSaisies] = useState("");
  
    useEffect(() => {
      console.log(classeGenerale !== '' && matieresGenerales !== '' && matiereSaisie)
        if(classeGenerale !== '' && matieresGenerales !== '' && matiereSaisie){
          setShowBtn(true)
        }else{
          setShowBtn(false)
        }
      // eslint-disable-next-line
    }, [classeGenerale,matieresGenerales])
    


    const handleChangeMatieres = (e, classe) => {
      const { value } = e.target;
      setMatieres((prevState) => ({
        [classe]: value,
      }));
    };
    

    const handleChangeClassesGenerales = (event) => {
      const { value } = event.target;
      setClasseGenerale(value)

      if(matieres.hasOwnProperty(value)){
        setMatiereSaisie(true)
      }else{
        setMatiereSaisie(false)
      }
      
    };

    useEffect(() => {
      if(matieres.hasOwnProperty(classeGenerale) && matieres[classeGenerale].length !== 0){
        setMatiereSaisie(true)
      }else{
        setMatiereSaisie(false)
      }
    }, [matieres])

  
    useEffect(() => {
        if(classeGenerale === ''){
            setMsgClasseGenerale("La classe generale est obligatoire")
        }else{
            setClasse(classeGenerale)
            setMsgClasseGenerale('')
        }
    }, [classeGenerale,setClasse])
    

    useEffect(() => {
      
      if(matiereSaisie){
        setMsgMatieresSaisies("")
        setMatieresGenerales(JSON.stringify(matieres))
      }else{
        setMsgMatieresSaisies("Veuillez saisir toutes les matieres")
      }
    }, [matiereSaisie,matieres,setMatieresGenerales])
  
    
    useEffect(() => {
        setMsgClasseGenerale('')
        setMsgMatieresSaisies('')
        setError('')
        setShowBtn(true);  
        // eslint-disable-next-line
    }, [])


    return (
        <div className='text-center mt-4'>
        <FormControl sx={{ m: 1, width: '100%' }}>
          <InputLabel id="demo-multiple-checkbox-label">Votre enfant est en quelle classe</InputLabel>
          <Select
            className="select"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={classeGenerale}
            label="Votre enfant est en quelle classe"
            onChange={handleChangeClassesGenerales}
          >
            <h6 style={{textAlign: 'center'}}>Primaire</h6>
            {classesGeneralesPrimaire.map((name) => (
              <MenuItem key={name} value={name}>{name}</MenuItem>
            ))}
            <h6 style={{textAlign: 'center'}}>Collège</h6>
            {classesGeneralesCollege.map((name) => (
              <MenuItem key={name} value={name}>{name}</MenuItem>
            ))}
            <h6 style={{textAlign: 'center'}}>Lycée série A</h6>
            {classesGeneralesLyceeA.map((name) => (
              <MenuItem key={name} value={name}>{name}</MenuItem>
            ))}
            <h6 style={{textAlign: 'center'}}>Lycée série D</h6>
            {classesGeneralesLyceeD.map((name) => (
              <MenuItem key={name} value={name}>{name}</MenuItem>
            ))}
            <h6 style={{textAlign: 'center'}}>Lycée série C</h6>
            {classesGeneralesLyceeC.map((name) => (
              <MenuItem key={name} value={name}>{name}</MenuItem>
            ))}
          </Select>
        </FormControl>

          <p style={{color: 'red'}}>{msgClasseGenerale}</p>

          <FormControl sx={{ m: 1, width: '100%' }}>
            <InputLabel id="demo-multiple-checkbox-label">{`Matieres pour ${classeGenerale}`} que vous vouliez que votre enfant reçoive un encadrement</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id={`${classeGenerale}-matieres`}
              value={matieres[classeGenerale] || []}
              input={<OutlinedInput label={`Matieres pour ${classeGenerale} que vous vouliez que votre enfant reçoive un encadrement`} />}
              onChange={(e) => handleChangeMatieres(e, classeGenerale)}
              multiple
            >
            {classesGeneralesPrimaire.includes(classeGenerale) && matieresGeneralesPrimaire.map(matiere => (
              <MenuItem key={matiere} value={matiere}>
                {matiere}
              </MenuItem>
            ))}
            {classesGeneralesCollege.includes(classeGenerale) && matieresGeneralesCollege.map(matiere => (
              <MenuItem key={matiere} value={matiere}>
                {matiere}
              </MenuItem>
            ))}
            {classesGeneralesLyceeA.includes(classeGenerale) && matieresGeneralesLyceeA.map(matiere => (
              <MenuItem key={matiere} value={matiere}>
                {matiere}
              </MenuItem>
            ))}
            {classesGeneralesLyceeD.includes(classeGenerale) && matieresGeneralesLyceeDC.map(matiere => (
              <MenuItem key={matiere} value={matiere}>
                {matiere}
              </MenuItem>
            ))}
            {classesGeneralesLyceeC.includes(classeGenerale) && matieresGeneralesLyceeDC.map(matiere => (
              <MenuItem key={matiere} value={matiere}>
                {matiere}
              </MenuItem>
            ))}


            </Select>
            </FormControl>

          <p style={{ color: "red" }}>{msgMatieresSaisies}</p>

        </div>
    )
}

export default Step3