import React from 'react'
import Footer from '../Footer'
import AppRoutes from '../AppRoutes';


function Content() {
  return (
    <div id="content" className="main-content sidebar-closed sbar-open">
        <div className="layout-px-spacing">
            <div className="middle-content container-xxl p-0">
                  <AppRoutes />
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Content