import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useUser } from "../../utils/UserContext";
import { useNotification } from "../../utils/NotificationContext";
import config from '../../utils/config'
import './index.css'
import moment from 'moment';

const Header = () => {

    const { userInfos } = useUser()
    const { notifEvaluations } = useNotification()

    const [showSideBar, setShowSideBar] = useState(false)
    const [dark, setDark] = useState(false)

    const [evaluations, setEvaluations] = useState([])


    moment.locale('fr');

    const logout = () => {
        localStorage.removeItem("user");
        localStorage.setItem("logout", "yes");
        toast.info("Déconnexion réussie", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        fetch(`${config.client}/?logout=yes`, { method: 'GET' })
        .then(response => {
          // Vous pouvez gérer la réponse ici si nécessaire
          console.log('Requête envoyée en arrière-plan');
        })
        .catch(error => {
          console.error('Erreur lors de l\'envoi de la requête en arrière-plan:', error);
        });
        window.location.href = config.client
    };
    
    useEffect(() => {


            let main = document.querySelector(".main-container")
            let overlay = document.querySelector(".overlay")
            main.classList.remove("sidebar-closed")
            main.classList.remove("sbar-open")
            overlay.classList.add("show")        

    }, [showSideBar])

    useEffect(() => {

        let vsc_initialized = document.querySelector("body")
        
        if(vsc_initialized){
            if(dark){
                vsc_initialized.classList.add("dark")
            }else{
                vsc_initialized.classList.remove("dark")
            }
        }

    }, [dark])
    

    const verifiedNoReadEvaluations = async () => {
        const evaluationsData = [];
        const user = JSON.parse(localStorage.getItem('user'));
        const elevesId = user && (user.typeCompte === "Professeur" ? user.ideleves : user.idEleves)
        
        const fetchEvaluations = async (idUser, eleveId) => {
            const response = await fetch(`${config.apiUrl}/encadrement/evaluations/${idUser}/${eleveId}`);
            const data = await response.json();
            return data;
        };

        if(elevesId){
            if (user && Array.isArray(elevesId)) {
                // Utilisez Promise.all pour attendre que toutes les requêtes réseau soient terminées
                const evaluationsPromises = elevesId.map(eleve => fetchEvaluations(user.id, eleve));
                const evaluationsDataArray = await Promise.all(evaluationsPromises);
        
                // Ajouter les données des évaluations à evaluationsData
                evaluationsDataArray.forEach(data => evaluationsData.push(...data));
            }
        
            // Filtrer les évaluations non lues
            const evaluationsFilter = evaluationsData.filter(evaluation => !evaluation.lu);
        
            // Mettre à jour les évaluations avec setEvaluations
            setEvaluations(evaluationsFilter);
        }

    };
    


    useEffect(() => {
        if(notifEvaluations.length === 0){
            verifiedNoReadEvaluations()
        }else{
            const evaluations = notifEvaluations.filter(notification => !notification.lu)
            const uniqueEvaluations = Array.from(new Set(evaluations && evaluations.map(evaluation => evaluation.id))).map(id => evaluations.find(evaluation => evaluation.id === id));
            setEvaluations(uniqueEvaluations);
        }

    }, [notifEvaluations])


  return (
    <div className="header-container">
        <ToastContainer />
        <header className="header navbar navbar-expand-sm expand-header">

          <a href="javascript:void(0);" onClick={e => setShowSideBar(!showSideBar)} className="sidebarCollapse">
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
          </a>

          <ul className="navbar-item flex-row ms-lg-auto ms-0">
              <li className="nav-item theme-toggle-item">
                  <a  onClick={e => setDark(!dark)} className="nav-link theme-toggle">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-moon dark-mode"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>
                      
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-sun light-mode"><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>
                  </a>
              </li>
              
              <li className="nav-item dropdown user-profile-dropdown  order-lg-0 order-1">
                        <Link to={"/notifications"}>
                            <div class="avatar-container">
                                <div class="avatar avatar-sm avatar-indicators">
                                {
                                    dark ?
                                    <svg style={{width: 40, height: 40}} viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="white" d="M12.0196 2.91016C8.7096 2.91016 6.0196 5.60016 6.0196 8.91016V11.8002C6.0196 12.4102 5.7596 13.3402 5.4496 13.8602L4.2996 15.7702C3.5896 16.9502 4.0796 18.2602 5.3796 18.7002C9.6896 20.1402 14.3396 20.1402 18.6496 18.7002C19.8596 18.3002 20.3896 16.8702 19.7296 15.7702L18.5796 13.8602C18.2796 13.3402 18.0196 12.4102 18.0196 11.8002V8.91016C18.0196 5.61016 15.3196 2.91016 12.0196 2.91016Z" stroke="#292D32" stroke-width="0.45600000000000007" stroke-miterlimit="10" stroke-linecap="round"></path> <path fill="white" d="M13.8699 3.19994C13.5599 3.10994 13.2399 3.03994 12.9099 2.99994C11.9499 2.87994 11.0299 2.94994 10.1699 3.19994C10.4599 2.45994 11.1799 1.93994 12.0199 1.93994C12.8599 1.93994 13.5799 2.45994 13.8699 3.19994Z" stroke="#292D32" stroke-width="0.45600000000000007" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> <path  fill="white" opacity="1" d="M15.0195 19.0601C15.0195 20.7101 13.6695 22.0601 12.0195 22.0601C11.1995 22.0601 10.4395 21.7201 9.89953 21.1801C9.35953 20.6401 9.01953 19.8801 9.01953 19.0601" stroke="#292D32" stroke-width="0.45600000000000007" stroke-miterlimit="10"></path> </g>
                                    </svg>:
                                    <svg style={{width: 40, height: 40}} viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12.0196 2.91016C8.7096 2.91016 6.0196 5.60016 6.0196 8.91016V11.8002C6.0196 12.4102 5.7596 13.3402 5.4496 13.8602L4.2996 15.7702C3.5896 16.9502 4.0796 18.2602 5.3796 18.7002C9.6896 20.1402 14.3396 20.1402 18.6496 18.7002C19.8596 18.3002 20.3896 16.8702 19.7296 15.7702L18.5796 13.8602C18.2796 13.3402 18.0196 12.4102 18.0196 11.8002V8.91016C18.0196 5.61016 15.3196 2.91016 12.0196 2.91016Z" stroke="#292D32" stroke-width="0.45600000000000007" stroke-miterlimit="10" stroke-linecap="round"></path> <path d="M13.8699 3.19994C13.5599 3.10994 13.2399 3.03994 12.9099 2.99994C11.9499 2.87994 11.0299 2.94994 10.1699 3.19994C10.4599 2.45994 11.1799 1.93994 12.0199 1.93994C12.8599 1.93994 13.5799 2.45994 13.8699 3.19994Z" stroke="#292D32" stroke-width="0.45600000000000007" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> <path opacity="1" d="M15.0195 19.0601C15.0195 20.7101 13.6695 22.0601 12.0195 22.0601C11.1995 22.0601 10.4395 21.7201 9.89953 21.1801C9.35953 20.6401 9.01953 19.8801 9.01953 19.0601" stroke="#292D32" stroke-width="0.45600000000000007" stroke-miterlimit="10"></path> </g>
                                    </svg>
                                }
                                
                                {
                                    evaluations.length > 0 &&
                                    <div class="number-overlay">
                                        {evaluations.length}
                                    </div>
                                }
                                </div>
                            </div>
                        </Link>
                </li>
            {
                userInfos &&
                <li className="nav-item dropdown user-profile-dropdown  order-lg-0 order-1">
                        <a href="javascript:void(0);" class="nav-link dropdown-toggle user" id="userProfileDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div class="avatar-container">
                                <div class="avatar avatar-sm avatar-indicators avatar-online">
                                    <img alt="avatar" src={config.apiUrl+userInfos.photo} class="rounded-circle" style={{height:40}} />
                                </div>
                            </div>
                        </a>
                        <div className="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown">
                            <div className="user-profile-section">
                                <div className="media mx-auto">
                                    <div className="emoji me-2">
                                        &#x1F44B;
                                    </div>
                                    <div className="media-body">
                                        <h5>{userInfos.last_name}</h5>
                                        <p>{userInfos.typeCompte}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown-item">
                                <Link to={'/profile'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> <span>Profile</span>
                                </Link>
                            </div>

                            <div className="dropdown-item">
                                <Link onClick={logout}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg> <span>Se déconnecter</span>
                                </Link>
                            </div>
                        </div>
                </li>
            }
            
          </ul>
        </header>
    </div>
  );
};

export default Header;
