import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // Plugin pour afficher une grille de jours
import timeGridPlugin from '@fullcalendar/timegrid'; // Plugin pour afficher une grille horaire
import moment from 'moment';
import 'moment/locale/fr'; // Importer les localisateurs français pour moment
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TextField from '@mui/material/TextField';
import { CircularProgress, MenuItem } from "@mui/material";
import { useUser } from '../../utils/UserContext';
import config from '../../utils/config'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Styles pour l'éditeur
// Configurer moment pour utiliser la langue française
moment.locale('fr');

function EmploiDuTemps() {
    const [modalShow, setModalShow] = useState(false);
    const [eleves, setEleves] = useState([])
    const [eleve, setEleve] = useState(null)
    const [events, setEvents] = useState(null)
    const [isLoadingEvents, setIsLoadingEvents] = useState(false)
    const [isLoadingHoursWork, setIsLoadingHoursWork] = useState(false)
    const [isLoadingSave, setIsLoadingSave] = useState(false)
    const [msgRaisonAnnulation, setMsgRaisonAnnulation] = useState("")
    const [showBtn, setShowBtn] = useState(true)
    const [block, setBlock] = useState(false)
    const [showListProfs, setShowListProfs] = useState(false)
    
    const [encadrement, setEncadrement] = useState(null)

    const [eventSaved, setEventSaved] = useState(null)

    const [detailsEvent, setDetailsEvent] = useState({})
    const { userInfos } = useUser()
    const [selectedStudent, setSelectedStudent] = useState("");
    const [selectedProf, setSelectedProf] = useState("");

    const calendarRef = useRef(null);

    useEffect(() => {
        if(userInfos && userInfos.typeCompte === "Tuteur"){
            if(detailsEvent && detailsEvent.status === "Réalisé"){
                setBlock(true)
            }else{
                setBlock(false)
            }
        }
    }, [detailsEvent])


    const fetchElevesData = async () => {
        const eleves_staging = [];
        if(userInfos){
          const enfants = userInfos.typeCompte === "Professeur" ? userInfos.ideleves : userInfos.idEleves
          for(const ideleve of enfants) {
            const url = `${config.apiUrl}/comptes/eleves/${ideleve}/`;
    
            try {
                const response = await fetch(url, {
                    method: "GET",
                });
                const data = await response.json();
    
                eleves_staging.push(data);
    
            } catch(error) {
                console.log("Erreur => ", error);
            }
        }
    
            // Mettez à jour le state une fois que toutes les données ont été récupérées
            setEleves(eleves_staging);            
        }
    };



    const fetchHoursWork = async (encadrementId, date, startHour, endHour) => {
        setIsLoadingHoursWork(true)
        const formattedDate = moment(date, 'DD MMMM YYYY').format('YYYY-MM-DD');
        const url = `${config.apiUrl}/encadrement/get_hours_work/${encadrementId}/${formattedDate}/${startHour}/${endHour}/`;
        const response = await fetch(url)
        const data = await response.json()
        setDetailsEvent(data.data)
        setIsLoadingHoursWork(false)
    }

    useEffect(() => {
        if(eventSaved){
            const newEvents = modifyEvent(events, eventSaved)
            setEvents(newEvents)
            const calendarApi = calendarRef.current.getApi();
            calendarApi.refetchEvents();
        }
    }, [eventSaved])

    useEffect(() => {
        let enc = null
        if(userInfos){
            if(userInfos.typeCompte === "Professeur"){
                if(eleve && eleve.encadrements && Array.isArray(eleve.encadrements)){
                    enc = eleve.encadrements.find(e => e.professeur === userInfos.id)
                    setEncadrement(enc)
                }
            }else{
                if(selectedProf){
                    if(eleve && eleve.encadrements && Array.isArray(eleve.encadrements)){
                        enc = eleve.encadrements.find(e => e.professeur === selectedProf)
                        setEncadrement(enc)
                    }
                    
                }
            }
        }

    }, [eleve, selectedProf])


    const modifyEvent = (events, newEvent) => {
        newEvent.heure_debut = newEvent.heure_debut.slice(0, -3);
        newEvent.heure_fin = newEvent.heure_fin.slice(0, -3);
        const dateObj = new Date(newEvent.date);
        const indiceJourSemaine = dateObj.getDay();
    
        const updatedEvents = events.map(event => {
            if (event.daysOfWeek[0] === indiceJourSemaine &&
                event.startTime === newEvent.heure_debut &&
                event.endTime === newEvent.heure_fin) {
                return {
                    ...event,
                    color: newEvent.status === 'Annulé' ? 'red' : (newEvent.status === 'Réalisé' ? 'green' : 'gray'),
                    title: newEvent.status,
                    // Ajoutez d'autres propriétés à modifier ici si nécessaire
                };
            }
            return event;
        });
    
        return updatedEvents;
    };
    


    const handleEventClick = (event) => {
    
        const date = moment(event.event.start).format('LL')
        const startHour = moment(event.event.start).format('LT')
        const endHour = moment(event.event.end).format('LT')
        setModalShow(true);
        if(encadrement){
            fetchHoursWork(encadrement.id, date, startHour, endHour)
        }
        
    };

    const closeModal = () => {
        setModalShow(false);
    };

    const save = async () => {
        setIsLoadingSave(true);
        const url = `${config.apiUrl}/encadrement/save_hours_work/`;

        const values = {
            status: detailsEvent.status,
            raison_annulation: detailsEvent.raison_annulation,
            matiere: detailsEvent.matiere,
            contenu: detailsEvent.contenu,
            utilisateurId: userInfos.id,
            id: detailsEvent.id,
            color: detailsEvent.status === 'Annulé' ? 'red' : (detailsEvent.status === 'Réalisé' ? 'green' : 'gray')// Ajoutez la couleur ici
        };
    
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(values)
        });
        const data = await response.json();
        setEventSaved(data.data);
        setModalShow(false);
        setIsLoadingSave(false);
    };

    function formatDate(OneDate) {
        // Exemple de date
        let date = new Date(OneDate);
    
        // Récupérer le jour de la semaine (0 pour Dimanche, 1 pour Lundi, etc.)
        let jourSemaine = date.getDay();
        
        // Tableau des jours de la semaine
        let jours = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
        
        // Tableau des mois
        let mois = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        
        // Récupérer le jour du mois, le mois et l'année
        let jourDuMois = date.getDate();
        let moisDuAn = date.getMonth();
        let annee = date.getFullYear();
        
        // Construire la chaîne formatée
        return `${jours[jourSemaine]} ${jourDuMois} ${mois[moisDuAn]} ${annee}`;
    }
    
    function getDatePourJour(jourSemaine) {
        // Tableau contenant les noms des jours de la semaine
        let joursSemaine = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    
        // Obtenir le jour de la semaine en tant que nombre (0 pour Dimanche, 1 pour Lundi, etc.)
        let numeroJour = joursSemaine.indexOf(jourSemaine);
    
        if (numeroJour === -1) {
            // Si le jour de la semaine n'est pas trouvé, renvoyer une chaîne vide
            return "Jour de la semaine invalide";
        }
    
        // Obtenir la date actuelle
        let date = new Date();
        let jourActuel = date.getDay(); // Renvoie le jour de la semaine en tant que nombre
    
        // Calculer la différence entre le jour actuel et le jour demandé
        let differenceJours = numeroJour - jourActuel;
    
        // Ajouter ou soustraire des jours à la date actuelle pour obtenir la date demandée
        date.setDate(date.getDate() + differenceJours);
    
        // Obtenir l'année, le mois et le jour au format YYYY-MM-DD
        let annee = date.getFullYear();
        let mois = (date.getMonth() + 1).toString().padStart(2, '0'); // Mois commence à 0
        let jour = date.getDate().toString().padStart(2, '0');
    
        // Renvoyer la date formatée
        return annee + "-" + mois + "-" + jour;
    }


    const fetchEleve = async () => {
        const url = `${config.apiUrl}/comptes/eleves/${selectedStudent}/`;

        const response = await fetch(url)
        const data = await response.json()
        setEleve(data)
    }



    const fetchEventsData = async () => {
        setIsLoadingEvents(true);
        let idProf = selectedProf === "" ? userInfos.id : selectedProf
        let url = `${config.apiUrl}/comptes/get_encadrement/${selectedStudent}/${idProf}/`;
        const response = await fetch(url);
        const dataEncadrement = await response.json();
        const idEncadrement = dataEncadrement.data.id
    
        const joursTravail = dataEncadrement.data.jours_travail;
        const myEvents = [];    

    
        for (const [jour, horaires] of Object.entries(joursTravail)) {
            const startTime = horaires.split(' - ')[0];
            const endTime = horaires.split(' - ')[1];
            const dayOfWeek = getDatePourJour(jour); // Obtenez la date actuelle pour chaque jour de la semaine
            
            const indiceDay = moment().isoWeekday(jour).isoWeekday()     
            url = `${config.apiUrl}/encadrement/get_hours_work/${idEncadrement}/${dayOfWeek}/${startTime}/${endTime}/`

            const response = await fetch(url)
            const data = await response.json()

            myEvents.push({
                title: data.data.status,
                daysOfWeek: [indiceDay],
                startTime: startTime,
                endTime: endTime,
                color: data.data.color // Récupérez la couleur depuis les données
            });
        }
        setEvents(myEvents);
        setIsLoadingEvents(false);
    };

    useEffect(() => {
        if(selectedStudent !== "" && userInfos){
            fetchEleve()
            if(userInfos.typeCompte === "Professeur"){
                fetchEventsData()
            }else{
                setShowListProfs(true)
            }
        }
    }, [selectedStudent])
    
    useEffect(() => {
        if(selectedProf !== "" && userInfos.typeCompte === 'Tuteur'){
            fetchEventsData()
        }
    }, [selectedProf])

    useEffect(() => {
        fetchElevesData()
    }, [userInfos])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if(name === 'status'){
            if(value === 'Annulé'){
                setShowBtn(false)
            }else{
                setShowBtn(true)
            }
        }

        if(name === 'status'){
            if(value === 'Réalisé'){
                if(userInfos && userInfos.typeCompte === "Tuteur"){
                    alert("Vous devez être le professeur pour appliquer ce type de status")
                }
            }
        }

        if(name === "raison_annulation"){
            if(value === ""){
                setMsgRaisonAnnulation("Veuillez préciser la raison")
                setShowBtn(false)
            }else{
                setMsgRaisonAnnulation('')
                setShowBtn(true)
            }
        }


        const eventDateTime = moment(detailsEvent.date + ' ' + detailsEvent.heure_fin);
        const currentDateTime = moment();

        if (currentDateTime.isBefore(eventDateTime)) {
            if (name === 'status' && value === 'Réalisé') {
                if(userInfos && userInfos.typeCompte === "Professeur"){
                    alert("Vous ne pouvez pas marquer comme réalisé le jour de travail que vous n'avez pas encore effectué")
                }
            }else{
                setDetailsEvent({
                    ...detailsEvent,
                    [name]: value,
                });
            }

        }else{
            if (name === 'status' && (value === 'Programmé')) {
                alert("Vous ne pouvez pas marquer comme Programmé le jour de travail que nous avons déjà dépassé")
            }else{
                setDetailsEvent({
                    ...detailsEvent,
                    [name]: value,
                });
        
            }       
        }
    };

  return (
    <div>
      <h4 className='text-center mt-4'>Emploi du Temps de la semaine</h4>

        <TextField
            select
            label="Sélectionnez l'élève"
            variant="outlined"
            fullWidth
            sx={{mb: 7, mt: 3}}
            value={selectedStudent}
            onChange={(e) => setSelectedStudent(e.target.value)}
        >
            {eleves && eleves.map((option) => (
            <MenuItem key={option.id} value={option.id}>
                {option.nom} {option.prenom}
            </MenuItem>
            ))}
        </TextField>

      {
        showListProfs &&
        <TextField
            select
            label="Sélectionnez le professeur concerné"
            variant="outlined"
            fullWidth
            sx={{mb: 7, mt: -3}}
            value={selectedProf}
            onChange={(e) => setSelectedProf(e.target.value)}
        >
            {eleve && Array.isArray(eleve.professeurs) && eleve.professeurs.map((option) => (
            <MenuItem key={option.id} value={option.id}>
                {option.last_name} {option.first_name} 
            </MenuItem>
            ))}
        </TextField>
      }

        {
            isLoadingEvents &&
            <center>
            <CircularProgress size={26} sx={{mt: 2 , mb: 3}} />
            </center>
        }

      {
        events  ?
        (userInfos.typeCompte === "Professeur" ? 
        <FullCalendar
            plugins={[ dayGridPlugin, timeGridPlugin ]}
            initialView='timeGridWeek' // Utiliser la vue timeGridWeek
            locale='fr'
            ref={calendarRef}
            events={events}
            headerToolbar={false}
            selectable={true}
            allDayText='Heures'
            slotDuration={'00:30:00'} // Définir l'écart entre les heures à 30 minutes
            eventClick={handleEventClick} // Gérer le clic sur un événement
        />
        : 
        (eleve && eleve.professeurs.length > 0 && 
        <FullCalendar
            plugins={[ dayGridPlugin, timeGridPlugin ]}
            initialView='timeGridWeek' // Utiliser la vue timeGridWeek
            locale='fr'
            ref={calendarRef}
            events={events}
            headerToolbar={false}
            selectable={true}
            allDayText='Heures'
            slotDuration={'00:30:00'} // Définir l'écart entre les heures à 30 minutes
            eventClick={handleEventClick} // Gérer le clic sur un événement
        />   
        )
        ):
        <>
        {
            eleve ?
            <h4 style={{marginTop: 40, textAlign: 'center'}}>
                Veuillez sélectionner le professeur concerné
            </h4>:
            <h4 style={{marginTop: 40, textAlign: 'center'}}>
                Veuillez sélectionner l'élève
            </h4>
        }
        </>
      }


      <Modal show={modalShow} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{detailsEvent && formatDate(detailsEvent.date)+", de "+detailsEvent.heure_debut+" à "+detailsEvent.heure_fin}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         {
            isLoadingHoursWork ?
            <center>
            <CircularProgress size={26} sx={{mt:3}} />
            </center>:
            <>
                {
                    userInfos &&
                    (
                        userInfos.typeCompte === "Professeur" ?
                        <TextField
                            select
                            label="Status"
                            name="status"
                            variant="outlined"
                            fullWidth
                            sx={{mb: 2}}
                            value={detailsEvent.status}
                            onChange={handleInputChange}
                        >
                            {['Annulé', 'Réalisé', 'Programmé'].map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                            ))}
                        </TextField>:
                        <TextField
                            select
                            label="Status"
                            name="status"
                            variant="outlined"
                            fullWidth
                            disabled={block}
                            sx={{mb: 2}}
                            value={detailsEvent.status}
                            onChange={handleInputChange}
                        >
                            {['Annulé','Réalisé','Programmé'].map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                            ))}
                        </TextField>
                    )
                }
                {
                    detailsEvent.status === "Annulé" &&
                    <TextField
                        label="Raison annulation"
                        name="raison_annulation"
                        required
                        variant="outlined"
                        sx={{mb: 2}}
                        error={Boolean(msgRaisonAnnulation)}
                        helperText={msgRaisonAnnulation}
                        fullWidth
                        value={detailsEvent.raison_annulation}
                        onChange={handleInputChange}
                    />
                }
                
                <TextField
                    select
                    label={`Matière ${ userInfos && userInfos.typeCompte === "Professeur" ? ( detailsEvent.status === "Réalisé" ? "enseignée" : (detailsEvent.status === "Programmé" ? "que vous aller enseigner" : "que vous deviez enseignée")) : ""}`}
                    name="matiere"
                    variant="outlined"
                    fullWidth
                    disabled={userInfos && userInfos.typeCompte === "Tuteur"}
                    value={detailsEvent.matiere}
                    onChange={handleInputChange}
                >
                    {detailsEvent && detailsEvent.encadrement && detailsEvent.encadrement.matieres.split(',').map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                    ))}
                </TextField>
                {
                    detailsEvent && detailsEvent.status === "Réalisé" &&
                    <h6 style={{ marginTop: 30, textAlign: 'center' }}>Ce qui a été fait en {detailsEvent.matiere}</h6>
                }
                
                {
                    detailsEvent.status === "Réalisé" && userInfos && userInfos.typeCompte === "Professeur" &&
                    <ReactQuill
                        theme="snow"
                        value={detailsEvent.contenu}
                        onChange={(content) => handleInputChange({ target: { name: 'contenu', value: content } })}
                        placeholder="Écrivez ici du texte avec des styles, des listes à puces, etc."
                        style={{ marginTop: 20 }}
                    />
                }
                {
                    userInfos && userInfos.typeCompte === "Tuteur" && detailsEvent && detailsEvent.status === "Réalisé" &&
                    <div
                        dangerouslySetInnerHTML={{ __html: detailsEvent.contenu }}
                        style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '4px' }}
                    />
                }
            </>
         }
        </Modal.Body>
        
        <Modal.Footer>
          <Button variant="danger" onClick={closeModal}>Annuler</Button>
         {
            detailsEvent.utilisateur ?
            (userInfos && (String(detailsEvent.utilisateur) === String(userInfos.id))) ?
            <Button variant="success" disabled={!showBtn} onClick={save}>{ isLoadingSave ? "Chargement..." : "Enregistrer"}</Button>:
            (
                detailsEvent.status === 'Programmé' &&
                <Button variant="success" onClick={save}>{ isLoadingSave ? "Chargement..." : "Enregistrer"}</Button>
            ):
            <Button variant="success" disabled={!showBtn} onClick={save}>{ isLoadingSave ? "Chargement..." : "Enregistrer"}</Button>
         }
         
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EmploiDuTemps;
