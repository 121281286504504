import InputCustom from "../../../InputCustom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Etudiant = (props) => {
  const {
    universite,
    filiere,
    annee,
    setUniversite,
    setFiliere,
    setAnnee,
    msgUniversite,
    msgFiliere,
    msgAnnee,
  } = props;

  return (
    <>
      <InputCustom
        label="Université"
        type="text"
        value={universite}
        placeholder="Ex : Marien NGOUABI"
        change={setUniversite}
      />
      <p style={{ color: "red" }}>{msgUniversite}</p>
      <InputCustom
        label="Filière"
        type="text"
        value={filiere}
        placeholder="Ex : Informatique"
        change={setFiliere}
      />
      <p style={{ color: "red" }}>{msgFiliere}</p>
      <FormControl sx={{ m: 1, width: '100%' }}>
        <InputLabel id="demo-simple-select-label">En quantième année êtes vous ?</InputLabel>
        <Select
          className="select"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={annee}
          label="En quantieme annee etes vous ?"
          onChange={e => setAnnee(e.target.value)}
        >
          <MenuItem value="premiere_annee">1ère annee</MenuItem>
          <MenuItem value="deuxieme_annee">2ème annee</MenuItem>
          <MenuItem value="troisieme_annee">3ème annee</MenuItem>
          <MenuItem value="quatrieme_annee">4ème annee</MenuItem>
          <MenuItem value="cinquieme_annee">5ème annee</MenuItem>
        </Select>
        <p style={{ color: "red" }}>{msgAnnee}</p>
      </FormControl>
    </>
  );
};

export default Etudiant;
