import React, { createContext, useContext, useEffect, useState } from 'react';
import Pusher from "pusher-js";


const NotificationContext = createContext();


export const NotificationProvider = ({ children }) => {
  const [notifEvaluations, setNotifEvaluations] = useState([]);

  useEffect(() => {
      Pusher.logToConsole = true;
      let pusher = new Pusher("ab345fd9c648dde35586", {
        cluster: "mt1",
      });

      pusher.connection.bind("connected", () => {
        console.log("Connected to Pusher!");
      });

      let channel = pusher.subscribe("notification-channel");

      channel.bind("notification-event", (data) => {
        const user = JSON.parse(localStorage.getItem('user'))
          if(user){
              if(data.evaluation){
                  if(user.typeCompte === "Professeur" && data.evaluation.professeur.id === user.id){
                      if (!data.evaluation.lu) {
                        // Vérifier si l'évaluation existe déjà dans le state
                        if (!notifEvaluations.some(evaluation => evaluation.id === data.evaluation.id)) {
                            // Ajouter l'évaluation uniquement si elle n'existe pas déjà dans le state
                            setNotifEvaluations(prevEvaluations => [...prevEvaluations, data.evaluation]);
                        }
                    }                  
                  }
              }

          }

        });
  }, [])

  return (
    <NotificationContext.Provider value={{ notifEvaluations, setNotifEvaluations }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};
