import React, { Fragment } from "react";
import InputCustom from "../../../InputCustom";

const Compte = (props) => {
  const {
    email,
    setEmail,
    msgEmail,
  } = props;

  return (
    <Fragment>
      <InputCustom
        type="email"
        label="Email"
        placeholder="Ex : john@exemple.com"
        value={email}
        change={setEmail}
      />
      <p style={{ color: "red" }}>{msgEmail}</p>
    </Fragment>
  );
};

export default Compte;
