import {useState, useEffect} from 'react'
import { TextField } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const validerHeures = (disponibilites) => {
  for (const jour in disponibilites) {
    const { heureDebut, heureFin } = disponibilites[jour];
    if (!heureDebut || !heureFin) {
      return false;
    }
  }
  return true;
};

const jours = [
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
  'Dimanche',
];

const Step4 = props => {

    const { lieuTravail,ville, setVille, setLieuTravail,difficulte,setDifficulte, periodeJournee, setPeriodeJournee,joursDisponibles, setJoursDisponibles,setShowBtn,setError } = props
    
    const [joursName, setJoursName] = useState(joursDisponibles.split(','));

    const [disponibilites, setDisponibilites] = useState(JSON.parse(periodeJournee));
    const [heureSaisie, setHeureSaisie] = useState(false);

    const [msgHeuresSaisies, setMsgHeuresSaisies] = useState("");
    const [msgLieuTravail, setMsgLieuTravail] = useState('')
    const [msgVille, setMsgVille] = useState('')
    const [msgDifficulte, setMsgDifficulte] = useState('')
    const [msgJoursDisponibles, setMsgJoursDisponibles] = useState('')


    const handleSelect = async (selected) => {
      setLieuTravail(selected);
      try {
        const results = await geocodeByAddress(selected);
        const latLng = await getLatLng(results[0]);
        console.log('Latitude et longitude:', latLng);
      } catch (error) {
        console.error('Erreur de géocodage:', error);
      }
    };
  
    const handleChangeLieuTravail = (e) => {
      setLieuTravail(e);
    };

    const handleChangeJours = (e) => {

      const {
        target: { value },
      } = e;
      setJoursName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
  
      const selectedDays = e.target.value;
      const updatedDisponibilites = {};
      selectedDays.forEach((day) => {
        if (!disponibilites[day]) {
          updatedDisponibilites[day] = {
            heureDebut: "",
            heureFin: "",
          };
        } else {
          updatedDisponibilites[day] = disponibilites[day];
        }
      });
      setDisponibilites(updatedDisponibilites);
    };

    useEffect(() => {
      const updatedHeureSaisie = validerHeures(disponibilites);
      setHeureSaisie(updatedHeureSaisie);
    }, [disponibilites])



    const handleChange = (e, jour) => {
      const { name, value } = e.target;
  
      if(value !== ""){
  
      let updatedValue = value; // Utilisez une variable let pour la valeur
      // Validation de l'heure de début
      if (name === "heureDebut") {
        const heureDebut = value.slice(0, 2);
        const minuteDebut = value.slice(3, 5);
        const heureFin = disponibilites[jour]?.heureFin?.slice(0, 2) || "";
        const minuteFin = disponibilites[jour]?.heureFin?.slice(3, 5) || "";
        if (heureDebut < "07" || heureDebut > "18") {
          // L'heure de début est inférieure à 7h, ajustement à 7h
          updatedValue = "07" + value.slice(2);
        } else if (heureFin && heureDebut > heureFin) {
          let h = Number(heureFin) - 2
          if((h+"").length === 1){
            h = "0"+h          
          }
          updatedValue = h + value.slice(2);
        }else if (heureDebut === heureFin){
          setHeureSaisie(false)
          
          let h = Number(heureDebut) - 2
          if((h+"").length === 1){
            h = "0"+h          
          }
          updatedValue = h +":"+minuteDebut;
          if (minuteDebut > minuteFin){
            updatedValue = heureDebut+":"+minuteFin 
          }
        }else{
          if(heureFin !== "" && minuteFin !== ""){
            const diffHours = heureFin - heureDebut;
            
            if(diffHours === 2){
              disponibilites[jour].heureFin = heureFin+":"+minuteDebut
            }
  
            if (diffHours < 2) {
              // L'écart entre l'heure de début et l'heure de fin est inférieur à 2 heures
              let h = Number(heureFin) - 2
              if((h+"").length === 1){
                h = "0"+h          
              }
              updatedValue = h +":"+minuteFin;
              // Tu peux également afficher un message d'erreur approprié
              // setError("L'écart entre l'heure de début et l'heure de fin doit être d'au moins 2 heures.");
            }
          }
        }
  
  
      }
      
  
      // Validation de l'heure de fin
      if (name === "heureFin") {
        const heureFin = value.slice(0, 2);
        const minuteFin = value.slice(3, 5);
        const heureDebut = disponibilites[jour]?.heureDebut?.slice(0, 2) || "";
        const minuteDebut = disponibilites[jour]?.heureDebut?.slice(3, 5) || "";
  
        if (heureFin > "18" || heureFin < "07") {
          // L'heure de fin est supérieure à 18h, ajustement à 18h
          updatedValue = "18" + value.slice(2);
        } else if (heureDebut && heureFin < heureDebut) {
          // L'heure de fin est inférieure à l'heure de début, ajustement à l'heure de début actuelle
          updatedValue = heureDebut + value.slice(2);
        }else if (heureDebut === heureFin){
          let h = Number(heureFin) + 2
          if((h+"").length === 1){
            h = "0"+h          
          }
          updatedValue = h + value.slice(2);
          if (minuteFin < minuteDebut){
            updatedValue = heureFin+":"+minuteDebut 
          }
        }else{
          if(heureDebut !== "" && minuteDebut !== ""){
            const diffHours = heureFin - heureDebut;
            if (diffHours < 2) {
              // L'écart entre l'heure de début et l'heure de fin est inférieur à 2 heures
              let h = Number(heureFin) + 1
              if((h+"").length === 1){
                h = "0"+h          
              }
              updatedValue = h + value.slice(2);
              // Tu peux également afficher un message d'erreur approprié
              // setError("L'écart entre l'heure de début et l'heure de fin doit être d'au moins 2 heures.");
            }
          }
        }
      }
  
      setDisponibilites((prevState) => ({
        ...prevState,
        [jour]: {
          ...prevState[jour],
          [name]: updatedValue,
        },
      }));
  
    }
    
    };

  
    useEffect(() => {
        if(lieuTravail !== '' && difficulte !== '' && periodeJournee !== "" && heureSaisie && joursDisponibles !== "" ){
          setShowBtn(true)
        }else{
          setShowBtn(false)
        }
        // eslint-disable-next-line
      }, [lieuTravail,joursDisponibles,periodeJournee,difficulte, ville])
    
    useEffect(() => {
        if(lieuTravail === ''){
          setMsgLieuTravail("Le lieu de travail est obligatoire")
        }else{
          setMsgLieuTravail('')
        }
    }, [lieuTravail])

    const handleVille = e => {
      setVille(e.target.value)
    }



    useEffect(() => {
        if(difficulte === ''){
          setMsgDifficulte("La difficulté de l'élève est obligatoire")
        }else{
          setMsgDifficulte('')
        }
    }, [difficulte])

    useEffect(() => {
      if (joursName.length === 0) {
        setMsgJoursDisponibles("Les jours disponibles sont obligatoires");
      }else if (joursName.length < 2) {
        setMsgJoursDisponibles("Veuillez disposer d'au moins deux jours de disponibles");
      }
      else {
        setMsgJoursDisponibles("");
      }
      setJoursDisponibles(joursName.join(","))
    }, [joursName,setJoursDisponibles]);
  
    useEffect(() => {
      if(heureSaisie){
        setMsgHeuresSaisies("")
        setPeriodeJournee(JSON.stringify(disponibilites));
      }else{
        if(joursName.length >= 2){
          setMsgHeuresSaisies("Veuillez saisir toutes les heures où vous êtes disponibles")
        }
      }
    }, [heureSaisie,disponibilites,setPeriodeJournee,joursName])

    useEffect(() => {
        setMsgLieuTravail('')
        setMsgVille('')
        setMsgJoursDisponibles('')
        setMsgHeuresSaisies("");
        setMsgDifficulte('')
        setError('')
        setShowBtn(true)
        // eslint-disable-next-line
    }, [])

    return (
        <div className='text-center mt-4'>
            
            
            {/* <InputCustom
              type="text"
              label="Où vont travailler le prof et l'élève"
              placeholder="Ex : 46 Rue missakou , Moukondo"
              value={lieuTravail}
              change={setLieuTravail}
            />
            <p style={{ color: "red",textAlign: 'center' }}>{msgLieuTravail}</p> */}
              <FormControl sx={{ width: '100%', mb: 3 }}>
                <InputLabel id="demo-simple-select-label">La Ville où se trouve l'enfant</InputLabel>
                <Select
                  className="select"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={ville}
                  label="La Ville où se trouve l'enfant"
                  onChange={handleVille}
                >
                  <MenuItem value="Brazzaville">Brazzaville</MenuItem>
                  <MenuItem value="Pointe_Noire">Pointe Noire</MenuItem>
                </Select>
                <p style={{ color: "red" }}>{msgVille}</p>
              </FormControl>

              <PlacesAutocomplete
                value={lieuTravail}
                onChange={handleChangeLieuTravail}
                onSelect={handleSelect}
                searchOptions={{
                  componentRestrictions: { country: 'CG' }, // 'CG' est le code ISO 3166-1 alpha-2 pour la République du Congo
                  types: ['address'] // Limite les résultats aux adresses
                }}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <TextField
                      type="text"
                      name="lieuTravail"
                      label="Où vont travailler le prof et l'élève"
                      className="w-100"
                      {...getInputProps({
                        placeholder: "Ex : 46 Rue Missakou, MOUKONDO",
                        variant: "outlined",
                        required: true,
                        error: Boolean(msgLieuTravail),
                        helperText: msgLieuTravail,
                        style: { marginBottom: '20px' }
                      })}
                    />
                    <div>
                      {loading && <div>Chargement...</div>}
                      {suggestions.map(suggestion => {
                        const style = {
                          backgroundColor: suggestion.active ? "#41b6e6" : "#fff"
                        };
                        return (
                          <div {...getSuggestionItemProps(suggestion, { style })}>
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <p style={{ color: "red",textAlign: 'center' }}>{msgLieuTravail}</p>
            
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              >
              <TextField
                id="outlined-multiline-static"
                label="Donnez plus de détails sur les difficultés de l'élève"
                multiline
                placeholder='il/elle a des sérieux problèmes en lecture et écriture ...'
                rows={3}
                value={difficulte}
                onChange={e => setDifficulte(e.target.value)} 
              />
            </Box>
            <p style={{color: 'red',textAlign: 'center'}}>{msgDifficulte}</p>


          <FormControl sx={{ m: 1, width: '100%' }}>
              <InputLabel id="demo-multiple-checkbox-label">Jours disponibles</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={joursName}
                onChange={handleChangeJours}
                input={<OutlinedInput label="Jours disponibles" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {jours.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={joursName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
              
          </FormControl>
          <p style={{ color: "red",textAlign: 'center' }}>{msgJoursDisponibles}</p>
          <p>{joursName.length < 2 ? "":"Vos heures de disponibilité pour chaque jour disponible (heure début et fin), l'heure de travail est comprise entre 07h et 18h"}</p>
          <p style={{fontWeight: 'bold'}}>        
            {joursName.length < 2 ? "":"l'écart entre vos heures dispos doit être au minimum de 2h"}
          </p>
          
          {joursName.length >= 2 && Object.keys(disponibilites).map((jour) => (
            <div key={jour}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                type="time"
                label={`Heure de début (${jour})`}
                id={`${jour}-heureDebut`}
                name="heureDebut"
                value={disponibilites[jour].heureDebut || ""}
                onChange={(e) => handleChange(e, jour)}
                sx={{ m: 1, width: `calc(100% - ${(10 + 14 * jour.length)}px)` }}
              />
              <TextField
                type="time"
                id={`${jour}-heureFin`}
                label={`Heure de fin (${jour})`}
                name="heureFin"
                value={disponibilites[jour].heureFin || ""}
                onChange={(e) => handleChange(e, jour)}
                sx={{ m: 1, width: `calc(100% - ${(10 + 14 * jour.length)}px)` }}
              />
            </Box>
            </div>
          ))}

          <p style={{ color: "red",textAlign: 'center' }}>{msgHeuresSaisies}</p>
        </div>
    )
}

export default Step4