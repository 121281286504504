import React from 'react'

function NotFound() {
  return (
    <div>
        <h2 className='text-center mt-4'>Page non trouvée</h2>
    </div>
  )
}

export default NotFound