import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InputCustom from "../InputCustom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import { useUser } from "../../utils/UserContext";
import config from '../../utils/config'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const UpdateTuteur = () => {
  const navigate = useNavigate();

  const [error, setError] = useState("");

  const { userInfos, setUserInfos } = useUser()


  useEffect(() => {
    if(userInfos){
      setFirstName(userInfos.first_name);
      setLastName(userInfos.last_name);
      setTelephone1(userInfos.telephone1);
      setTelephone2(userInfos.telephone2);
      setProfession(userInfos.profession);
      setVille(userInfos.ville);
      setEmail(userInfos.email);
    }
  }, [userInfos])

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [telephone1, setTelephone1] = useState("");
  const [telephone2, setTelephone2] = useState("");
  const [profession, setProfession] = useState("");
  const [ville, setVille] = useState("");
  const [email, setEmail] = useState("");

  const [msgFirstName, setMsgFirstName] = useState("");
  const [msgEmail, setMsgEmail] = useState("");
  const [msgLastName, setMsgLastName] = useState("");
  const [msgTelephone1, setMsgTelephone1] = useState("");
  const [msgTelephone2, setMsgTelephone2] = useState("");
  const [msgProfession, setMsgProfession] = useState("");
  const [msgVille, setMsgVille] = useState("");

  const [showBtn, setShowBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let formatTelephone = /^(06|05|04)[0-9]{7}$/;
    if (
      firstName !== "" &&
      lastName !== "" &&
      ville !== "" &&
      telephone1 !== "" &&
      formatTelephone.test(telephone1) &&
      (formatTelephone.test(telephone2) || telephone2 === "") &&
      email !== "" &&
      validateEmail(email) &&
      profession !== ""
    ) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
    // eslint-disable-next-line
  }, [
    firstName,
    lastName,
    telephone1,
    email,
    telephone2,
    profession,
    ville
  ]);

  useEffect(() => {
    if (firstName === "") {
      setMsgFirstName("Le prénom est obligatoire");
    } else {
      setMsgFirstName("");
    }
  }, [firstName]);

  useEffect(() => {
    if (profession === "") {
      setMsgProfession("La profession est obligatoire");
    } else {
      setMsgProfession("");
    }
  }, [profession]);

  useEffect(() => {
    if (ville === "") {
      setMsgVille("La ville est obligatoire");
    } else {
      setMsgVille("");
    }
  }, [ville]);

  useEffect(() => {
    if (lastName === "") {
      setMsgLastName("Le nom est obligatoire");
    } else {
      setMsgLastName("");
    }
  }, [lastName]);


  useEffect(() => {
    if(email === ''){
      setMsgEmail("L'email est obligatoire")
    }else{
      if(!validateEmail(email)){
        setMsgEmail("L'email n'est pas valide")
      }else{
        setMsgEmail('')
      }
    }
  }, [email])



  useEffect(() => {
    if (telephone1 === "") {
      setMsgTelephone1("Le premier numero de tel est obligatoire");
    } else {
      let formatTelephone = /^(06|05|04)[0-9]{7}$/;
      if (formatTelephone.test(telephone1)) {
          setMsgTelephone1("");
      } else {
          setShowBtn(false)
          setMsgTelephone1("Le format du numéro de téléphone est invalide");
      }
    }
  }, [telephone1]);

  useEffect(() => {
    setError("")
    if (telephone1 === telephone2) {
      setMsgTelephone2("Les deux numéros ne doivent pas être identiques");
    } else {
      let formatTelephone = /^(06|05|04)[0-9]{7}$/;
      if(telephone2 !== ""){
        if (formatTelephone.test(telephone2)) {
            setMsgTelephone2("");
        } else {
            setMsgTelephone2("Le format du numéro de téléphone est invalide");
        }
      }else{
        setMsgTelephone2("");
      }
    }
  }, [telephone1, telephone2, setError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    setIsLoading(true)
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("telephone1", telephone1);
    formData.append("telephone2", telephone2);
    formData.append("email", email);
    formData.append("username", email);
    formData.append("profession", profession);
    formData.append("ville", ville);

    const url = `${config.apiUrl}/comptes/update/tuteur/${userInfos.id}`;

    fetch(url, {
      method: "PUT",
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          setError(data.message);
        } else {
          localStorage.setItem("user", JSON.stringify(data));
          setUserInfos(data)
          toast.success('Mise à jour réussie 😊!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          navigate("/profile");
        }
        setIsLoading(false)
      })
      .catch((error) => console.log("Erreur => ", error));
  };

  return (
  <section className="signup-area">
    <ToastContainer />
    <div className="container">
      <div className="row align-items-center">
        <div className="col-12">
          <div className="signup-form">
              <form method="post">
                <div className="row">
                  <InputCustom
                    type="text"
                    label="Prenom"
                    placeholder="Ex : Mondesir"
                    value={firstName}
                    change={setFirstName}
                  />
                  <p style={{ color: "red",textAlign: 'center' }}>{msgFirstName}</p>
                  
                  <InputCustom
                    type="text"
                    label="Nom"
                    placeholder="Ex : DISSIDI"
                    value={lastName}
                    change={setLastName}
                  />
                  <p style={{ color: "red",textAlign: 'center'  }}>{msgLastName}</p>
                  <InputCustom
                    type="text"
                    label="Premier numéro de telephone"
                    placeholder="Ex: 064723424"
                    value={telephone1}
                    change={setTelephone1}
                  />
                  <p style={{ color: "red",textAlign: 'center'  }}>{msgTelephone1}</p>
                  <InputCustom
                    type="text"
                    label="Deuxieme numéro de telephone"
                    placeholder="Ex: 055652586"
                    value={telephone2}
                    change={setTelephone2}
                  />
                  <p style={{ color: "red",textAlign: 'center'  }}>{msgTelephone2}</p>
                  <InputCustom
                    type="email"
                    label="Email"
                    placeholder="Ex : test@exemple.com"
                    value={email}
                    change={setEmail}
                  />
                  <p style={{ color: "red",textAlign: 'center'  }}>{msgEmail}</p>

                  <FormControl sx={{ m: 1, width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Votre ville</InputLabel>
                    <Select
                      className="select"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={ville}
                      label="Votre ville"
                      onChange={e => setVille(e.target.value)}
                    >
                      <MenuItem value="Brazzaville">Brazzaville</MenuItem>
                      <MenuItem value="Pointe_Noire">Pointe Noire</MenuItem>
                    </Select>
                    <p style={{ color: "red",textAlign: 'center' }}>{msgVille}</p>
                  </FormControl>

                  <InputCustom
                    type="text"
                    label="Votre Profession"
                    placeholder="Ex : commerçante"
                    value={profession}
                    change={setProfession}
                  />
                  <p style={{ color: "red",textAlign: 'center' }}>{msgProfession}</p>
                  <div className="col-lg-12">
                    {
                      showBtn &&
                      <LoadingButton
                      loading={isLoading}
                      variant='contained'
                      loadingIndicator={<CircularProgress color="inherit" size={30} />}
                      sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2, padding: 4, color:'white'}}
                      onClick={handleSubmit}
                      >
                        Mettre à jour
                      </LoadingButton>
                    }
                  </div>
                  <p style={{ color: "red" }}>{error}</p>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
};

export default UpdateTuteur;
