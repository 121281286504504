import React, { useEffect, useState } from "react";
import InputCustom from "../../InputCustom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';


const Step1 = (props) => {
  const {
    firstName,
    lastName,
    dateNaissance,
    setError,
    ville,
    adresse,
    sexe,
    setSexe,
    statut,
    setFirstName,
    setLastName,
    setDateNaissance,
    setVille,
    setAdresse,
    setStatut,
    setShowBtn,
    telephone1,
    setTelephone1,
    telephone2,
    setTelephone2,
  } = props;

  const [msgFirstName, setMsgFirstName] = useState("");
  const [msgLastName, setMsgLastName] = useState("");
  const [msgTelephone1, setMsgTelephone1] = useState("");
  const [msgTelephone2, setMsgTelephone2] = useState("");
  const [msgDateNaissance, setMsgDateNaissance] = useState("");
  const [msgVille, setMsgVille] = useState("");
  const [msgAdresse, setMsgAdresse] = useState("");
  const [msgStatut, setMsgStatut] = useState("");
  const [msgSexe, setMsgSexe] = useState("");

  const handleSelect = async (selected) => {
    setAdresse(selected);
    try {
      const results = await geocodeByAddress(selected);
      const latLng = await getLatLng(results[0]);
      console.log('Latitude et longitude:', latLng);
    } catch (error) {
      console.error('Erreur de géocodage:', error);
    }
  };

  const handleChange = (e) => {
    setAdresse(e);
  };

  useEffect(() => {
    let formatTelephone = /^(06|05|04)[0-9]{7}$/;
    let annee = new Date(dateNaissance).getFullYear();

    if (
      firstName !== "" &&
      lastName !== "" &&
      dateNaissance !== "" &&
      adresse !== "" &&
      ville !== "" &&
      telephone1 !== "" &&
      formatTelephone.test(telephone1) &&
      (formatTelephone.test(telephone2) || telephone2 === "") &&
      statut !== "" &&
      sexe !== "" &&
      (annee >= 1978 && annee <= 2005) 
    ) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
    // eslint-disable-next-line
  }, [firstName, lastName, dateNaissance, adresse, ville, statut,sexe,telephone1, telephone2]);

  useEffect(() => {
    if (statut === "") {
      setMsgStatut("Le statut est obligatoire");
    } else {
      setMsgStatut("");
    }
  }, [statut]);

  useEffect(() => {
    if (ville === "") {
      setMsgVille("La ville est obligatoire");
    } else {
      setMsgVille("");
    }
  }, [ville]);

  useEffect(() => {
    if (firstName === "") {
      setMsgFirstName("Le prenom est obligatoire");
    } else {
      setMsgFirstName("");
    }
  }, [firstName]);

  useEffect(() => {
    if (sexe === "") {
      setMsgSexe("Le sexe est obligatoire");
    } else {
      setMsgSexe("");
    }
  }, [sexe]);

  useEffect(() => {
    if (lastName === "") {
      setMsgLastName("Le nom est obligatoire");
    } else {
      setMsgLastName("");
    }
  }, [lastName]);

  useEffect(() => {
    if(dateNaissance === ''){
      setMsgDateNaissance("La date de naissance est obligatoire")
    }else{
      let annee = new Date(dateNaissance).getFullYear();
      if (annee >= 1978 && annee <= 2005) {
        setMsgDateNaissance('')
      } else {
        setMsgDateNaissance("L'âge doit être comprise entre 18 et 45 ans");
      }
    }
  }, [dateNaissance])

  useEffect(() => {
    if (adresse === "") {
      setMsgAdresse("L'adresse est obligatoire");
    } else {
      setMsgAdresse("");
    }
  }, [adresse]);

  useEffect(() => {
    if (telephone1 === "") {
      setMsgTelephone1("Le premier numero de tel est obligatoire");
    } else {
      let formatTelephone = /^(06|05|04)[0-9]{7}$/;
      if (formatTelephone.test(telephone1)) {
          setMsgTelephone1("");
      } else {
          setMsgTelephone1("Le format du numéro de téléphone est invalide");
      }
    }
  }, [telephone1]);

  useEffect(() => {
    setError("")
    if (telephone1 === telephone2) {
      setMsgTelephone2("Les deux numéros ne doivent pas être identiques");
    } else {
      let formatTelephone = /^(06|05|04)[0-9]{7}$/;
      if(telephone2 !== ""){
        if (formatTelephone.test(telephone2)) {
            setMsgTelephone2("");
        } else {
            setMsgTelephone2("Le format du numéro de téléphone est invalide");
        }
      }else{
        setMsgTelephone2("");
      }
    }
  }, [telephone1, telephone2, setError]);

  useEffect(() => {
    setMsgFirstName("");
    setMsgSexe("");
    setMsgLastName("");
    setMsgVille("");
    setMsgAdresse("");
    setMsgDateNaissance("");
    setMsgStatut("");
    setMsgTelephone1("");
    setMsgTelephone2("");
  }, []);

  const handleStatut = (e) => {
    setStatut(e.target.value);
  };

  return (
    <div className="row mt-5">
      <InputCustom
        type="text"
        label="Prenom(s)"
        placeholder="Ex : Mondesir"
        value={firstName}
        change={setFirstName}
      />
      <p style={{ color: "red",textAlign: 'center' }}>{msgFirstName}</p>
      <InputCustom
        type="text"
        label="Nom(s)"
        placeholder="Ex : NTSOUMOU"
        value={lastName}
        change={setLastName}
      />
      <p style={{ color: "red",textAlign: 'center' }}>{msgLastName}</p>
      <InputCustom
        type="date"
        label="Date de naissance"
        value={dateNaissance}
        change={setDateNaissance}
      />
      <p style={{ color: "red",textAlign: 'center' }}>{msgDateNaissance}</p>
      <FormControl sx={{ m: 1, width: '100%' }}>
        <InputLabel id="demo-simple-select-label">Votre sexe</InputLabel>
        <Select
          className="select"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={sexe}
          label="Votre sexe"
          onChange={e => setSexe(e.target.value)}
        >
          <MenuItem value="Masculin">Masculin</MenuItem>
          <MenuItem value="Feminin">Feminin</MenuItem>
        </Select>
        <p style={{ color: "red",textAlign: 'center' }}>{msgSexe}</p>
      </FormControl>

      <FormControl sx={{ m: 1, width: '100%' }}>
        <InputLabel id="demo-simple-select-label">Votre ville</InputLabel>
        <Select
          className="select"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={ville}
          label="Votre ville"
          onChange={e => setVille(e.target.value)}
        >
          <MenuItem value="brazzaville">Brazzaville</MenuItem>
          <MenuItem value="Pointe_Noire">Pointe Noire</MenuItem>
        </Select>
        <p style={{ color: "red",textAlign: 'center' }}>{msgVille}</p>
      </FormControl>
      
      {/* <InputCustom
        type="text"
        label="Adresse"
        placeholder="Ex : 46 Rue Missakou, MOUKONDO"
        value={adresse}
        change={setAdresse}
      /> */}
      <PlacesAutocomplete
        value={adresse}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{
          componentRestrictions: { country: 'CG' }, // 'CG' est le code ISO 3166-1 alpha-2 pour la République du Congo
          types: ['address'] // Limite les résultats aux adresses
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <TextField
              type="text"
              name="adresse"
              label="Adresse"
              className="w-100"
              {...getInputProps({
                placeholder: "Ex : 46 Rue Missakou, MOUKONDO",
                variant: "outlined",
                required: true,
                error: Boolean(msgAdresse),
                helperText: msgAdresse,
                style: { marginBottom: '20px' }
              })}
            />
            <div>
              {loading && <div>Chargement...</div>}
              {suggestions.map(suggestion => {
                const style = {
                  backgroundColor: suggestion.active ? "#41b6e6" : "#fff"
                };
                return (
                  <div {...getSuggestionItemProps(suggestion, { style })}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <p style={{ color: "red",textAlign: 'center' }}>{msgAdresse}</p>

      <InputCustom
        type="text"
        label="Premier numéro de téléphone"
        placeholder="Format : 064723424"
        value={telephone1}
        change={setTelephone1}
      />
      <p style={{ color: "red",textAlign: 'center' }}>{msgTelephone1}</p>
      <InputCustom
        type="text"
        label="Deuxieme numéro de téléphone(optionnel)"
        placeholder="deuxieme num tel"
        value={telephone2}
        change={setTelephone2}
      />
      <p style={{ color: "red",textAlign: 'center' }}>{msgTelephone2}</p>
      <FormControl sx={{ m: 1, width: '100%' }}>
        <InputLabel id="demo-simple-select-label">Statut</InputLabel>
        <Select
          className="select"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={statut}
          label="Votre statut"
          onChange={handleStatut}
        >
          <MenuItem value="etudiant_sans_experience">Etudiant (sans expérience dans l'encadrement à domicile)</MenuItem>
          <MenuItem value="etudiant_avec_experience">Etudiant (avec expérience dans l'encadrement à domicile)</MenuItem>
          <MenuItem value="diplome_sans_experience">Diplômé (sans expérience dans l'encadrement à domicile)</MenuItem>
          <MenuItem value="diplome_avec_experience">Diplômé (avec expérience dans l'encadrement à domicile)</MenuItem>
        </Select>
      </FormControl>
      <p style={{ color: "red" }}>{msgStatut}</p>
    </div>
  );
};

export default Step1;
