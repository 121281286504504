import React, { useEffect, useState } from 'react'
import { useUser } from '../../utils/UserContext';
import StarRating from '../../Components/StarRating';
import { CircularProgress } from '@mui/material';
import config from '../../utils/config'

function Todo() {
    const urlParams = new URLSearchParams(window.location.search);
    const {userInfos} = useUser()
    const [eleves, setEleves] = useState([])
    const dataUrlEleve = urlParams.get('idEleve');
    const dataUrlProf = urlParams.get('idProf');
    const [isLoading, setIsLoading] = useState(false)
    const [prof, setProf] = useState(null)

    useEffect(() => {
        if(userInfos){
            if(userInfos.typeCompte === "Tuteur"){
                if(!(dataUrlProf && dataUrlEleve)){
                    window.location.href = "/"
                }
                setIsLoading(true)
                const url = `${config.apiUrl}/comptes/professeurs/${dataUrlProf}/`;
                fetch(url, {
                    method: "GET",
                })
                .then(response => response.json())
                .then(data => {
                    setProf(data)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.log("Erreur => ", error)
                    setIsLoading(false)
                }); 
                setIsLoading(false)
                
            }else{
                const fetchElevesData = async () => {
                    const eleves_staging = [];
                    setIsLoading(true)
                    for(const ideleve of userInfos.ideleves) {
                        const url = `${config.apiUrl}/comptes/eleves/${ideleve}/`;
                
                        try {
                            const response = await fetch(url, {
                                method: "GET",
                            });
                            const data = await response.json();
                            eleves_staging.push(data);
                            setIsLoading(false)
                        } catch(error) {
                            console.log("Erreur => ", error);
                            setIsLoading(false)
                        }
                    }
    
                    // Mettez à jour le state une fois que toutes les données ont été récupérées
                    setEleves(eleves_staging);
                };
    
                fetchElevesData();
                setIsLoading(false)
            }
        }
    }, [userInfos]) 
    

  const tableaux = (id) => {
    const CryptoJS = require('crypto-js');
    const dataToEncrypt = localStorage.getItem("user");
    const encryptedData = CryptoJS.AES.encrypt(dataToEncrypt, 'R#i$5&@9lP!s2*').toString();
    const url = `${config.clientBoards}/boards?data=${encodeURIComponent(encryptedData)}&id=${id}`;
    window.location.href = url;
  }

  return (
    <div>
        <h3 className='text-center mt-4'>{prof ? "Prof concerné" : <center><CircularProgress size={40} /></center>}</h3>
        <center>
        <div className="widget-content widget-content-area">
            {
                userInfos && userInfos.typeCompte === "Tuteur" && prof &&
                <div className="text-center user-info">
                    <img src={config.apiUrl+prof.photo} alt="avatar" />
                    <p className="">{prof.last_name} {prof.first_name}</p>
                </div>
            }
            <div className="user-info-list">

                <div className="">
                    <ul className="contacts-block list-unstyled">          
                        {
                            
                            userInfos && userInfos.typeCompte === "Tuteur" &&
                            isLoading ? <center><CircularProgress sx={{mt: 20}} size={50} /></center> :
                            (prof &&
                            <>
                                <li className="contacts-block__item">
                                    - Total des élèves encadrés : {prof.ideleves && prof.ideleves.length}
                                </li>
                                {/* <li className="contacts-block__item">
                                    - Age : {getAge(userInfos.date_naissance)} ans</li>     */}
                                <li className="contacts-block__item">
                                    - Adresse : {prof.adresse}
                                </li>
                                <li className="contacts-block__item">
                                    <a href="mailto:example@mail.com">- Email : {prof.email}</a>
                                </li>
                                <li className="contacts-block__item">
                                    - Telephone : {prof.telephone1} , {prof.telephone2}
                                </li>
                            </>)
                        }                        

                    </ul>
                </div>                                    
            </div>
        </div>
        <div className="row layout-spacing ">
            {
                userInfos && userInfos.typeCompte === "Professeur" &&
                isLoading ? <center><CircularProgress sx={{mt: 20}} size={50} /></center> :
                
                (eleves && eleves.map(eleve => {
                    return (
                        <>
                        <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
                            <div className="user-profile">
                                <div className="widget-content widget-content-area">
                                    <div className="text-center user-info">
                                        <img src={config.apiUrl+eleve.photo} alt="avatar" />
                                        <p className=""><b>NOM :</b> {eleve.nom}</p>
                                        <p className=""><b>PRENOM :</b> {eleve.prenom}</p>
                                        <p className=""><b>ECOLE :</b> {eleve.ecole}</p>
                                    </div>
                                    <div className="user-info-list mt-4">
                                        <button className='btn btn-primary' onClick={() => tableaux(eleve.id)}>Voir les travaux</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                    )
                }))
            }

            {
                userInfos && userInfos.typeCompte === "Tuteur" &&
                <div className='mt-4 text-center'>
                    <StarRating idEleve={dataUrlEleve}  idProf={dataUrlProf} userInfos={userInfos} />
                </div>
            }
            </div>
        </center>
    </div>
  )
}

export default Todo