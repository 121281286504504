import React from "react";
import InputCustom from "../../../InputCustom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Enseignant = (props) => {
  const {
    dernierDiplome,
    infoDiplome,
    setDernierDiplome,
    setInfoDiplome,
    msgDernierDiplome,
    msgInfoDiplome,
  } = props;

  return (
    <>
      <FormControl sx={{ m: 1, width: '100%' }}>
        <InputLabel id="demo-simple-select-label">Quel est votre dernier diplôme ?</InputLabel>
        <Select
          className="select"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={dernierDiplome}
          label="Quel est votre dernier diplome ?"
          onChange={(e) => setDernierDiplome(e.target.value)}
        >
          <MenuItem value="bac">BAC</MenuItem>
          <MenuItem value="licence">Licence</MenuItem>
          <MenuItem value="master">Master</MenuItem>
        </Select>
      </FormControl>
      <p style={{ color: "red" }}>{msgDernierDiplome}</p>
      <InputCustom
        type="text"
        label="Donnez-nous plus d'infos sur votre diplome"
        placeholder={`Ex : ${dernierDiplome === 'bac' ? `${dernierDiplome && (dernierDiplome.charAt(0).toUpperCase() + dernierDiplome.slice(1))} Série D` : `${dernierDiplome && (dernierDiplome.charAt(0).toUpperCase() + dernierDiplome.slice(1))} en Informatique`}`}
        value={infoDiplome}
        change={setInfoDiplome}
      />
      <p style={{ color: "red" }}>{msgInfoDiplome}</p>
    </>
  );
};

export default Enseignant;
